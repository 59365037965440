import axios from 'axios'
import getURL from '../configuration'
const SERVER = getURL()

const customHeader = () => { return { headers: { 'Authorization': localStorage.getItem('usertoken') } } }

export const MAX_RANKINGS_ENTRIES = 25

export const getAllTimeRankings = () => {
    return axios
        .get(SERVER + '/rankings/all', customHeader())
        .then(response => {
            return response
        })
        .catch(err => {
            return err.response
        })
}

export const getMonthRankings = () => {
    return axios
        .get(SERVER + '/rankings/month', customHeader())
        .then(response => {
            return response
        })
        .catch(err => {
            return err.response
        })
}