import React, { Component } from 'react'
import { MDBContainer, MDBInput, MDBBtn } from 'mdbreact'
import '../Form/Form.css'
import { getAccountDetails, updateAccount, updateAccountHelper } from '../UserFunctions'
import PhoneInput from 'react-phone-number-input'
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import { testValidEmail } from '../Form/FormFunctions'

var updateError = false;
var updateErrorMessage = "";

class Account extends Component {
    constructor() {
        super()
        this.state = {
            userName: '',
            phone: '',
            email: '',
            firstName: '',
            lastName: '',
            country: '',
            state: '',
            hobbies: '',
            confirmed: false,
            flagged: false
        }
        this.onChange = this.onChange.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
    }

    componentDidMount() {
        const curUserToken = localStorage.getItem('usertoken');
        if (curUserToken === null) {
            this.props.history.push('/login')
        }
        else {
            getAccountDetails(localStorage.getItem("userName")).then(res => {
                this.setState({
                    userName: res.data.userName,
                    phone: res.data.phone,
                    email: res.data.email,
                    firstName: res.data.firstName,
                    lastName: res.data.lastName,
                    password: res.data.password,
                    country: res.data.country,
                    state: res.data.state,
                    hobbies: res.data.hobbies,
                    isAdmin: res.data.isAdmin,
                    confirmed: res.data.confirmed,
                    flagged: res.data.flagged
                })
            })
            document.getElementById("viewAccount").save.disabled = true;
            document.getElementById("viewAccount").phone.disabled = true;
        }
    }

    editHandler() {
        const f = document.getElementById("viewAccount");
        f.phone.disabled = false;
        f.email.disabled = false;
        f.firstName.disabled = false;
        f.lastName.disabled = false;
        f.country.disabled = false;
        f.state.disabled = false;
        f.hobbies.disabled = false;
        f.edit.disabled = true;
        f.save.disabled = false;
    }

    cancelHandler() {
        window.location.reload();
    }

    onChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    phoneInputHandler(value) {
        this.setState({ phone: value });
    }

    selectCountry(value) {
        this.setState({ country: value });
    }

    selectRegion(value) {
        this.setState({ state: value });
    }

    async onSubmit(e) {
        e.preventDefault();

        this.setState({
            validEmail: await testValidEmail(this.state.email)
        });

        if (!this.state.validEmail) {
            return
        }

        const updatedUser = {
            userName: this.state.userName,
            phone: this.state.phone,
            email: this.state.email,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            password: this.state.password,
            country: this.state.country,
            state: this.state.state,
            hobbies: this.state.hobbies,
            isAdmin: this.state.isAdmin,
            confirmed: this.state.confirmed,
            flagged: this.state.flagged
        }

        updateAccount(updatedUser).then(res => {
            let response = updateAccountHelper(res, '/acconnt')
            updateError = response.error;
            updateErrorMessage = response.message;
        })
    }

    render() {

        const updateErrorRender = (
            <div>
                {updateErrorMessage}
            </div>
        )

        return (
            <MDBContainer>
                <form className="mx-auto custom-form" id="viewAccount" onSubmit={this.onSubmit}>
                    <h1 className="h1-upward"> Account Details </h1>
                    <MDBInput name="userName" id="userName" maxLength="30" label="Username" value={this.state.userName} onChange={this.onChange} required disabled />
                    <PhoneInput id="phone" value={this.state.phone} onChange={(val) => this.phoneInputHandler(val)} />
                    <MDBInput type="email" name="email" id="email" maxLength="30" label="Email" value={this.state.email} onChange={this.onChange} required disabled />
                    <MDBInput name="firstName" id="firstName" maxLength="30" label="First Name" value={this.state.firstName} onChange={this.onChange} required disabled />
                    <MDBInput name="lastName" id="lastName" maxLength="30" label="Last Name" placeholder={this.state.lastName} value={this.state.lastName} onChange={this.onChange} required disabled />
                    <CountryDropdown valueType="short" id="country" className="browser-default custom-select mb-4" value={this.state.country} onChange={(val) => this.selectCountry(val)} required disabled />
                    <RegionDropdown countryValueType="short" id="state" className="browser-default custom-select mb-4" country={this.state.country} value={this.state.state} onChange={(val) => this.selectRegion(val)} required disabled />
                    <div className="form-group">
                        <label id="hobbies-label" htmlFor="hobbies"> Hobbies </label>
                        <textarea name="hobbies" id="hobbies" className="form-control" maxLength="300" rows="5" value={this.state.hobbies} onChange={this.onChange} disabled />
                    </div>
                    <MDBBtn type="button" color="primary" id="edit" onClick={this.editHandler}> Edit </MDBBtn>
                    <MDBBtn type="submit" color="success" id="save"> Save </MDBBtn>
                    <MDBBtn type="button" color="danger" id="cancel" onClick={this.cancelHandler}> Cancel </MDBBtn>
                    <div>
                        <span style={{ color: 'red' }}>
                            {updateError ? updateErrorRender : ''}
                        </span>
                    </div>
                </form>
            </MDBContainer>
        )
    }
}

export default Account