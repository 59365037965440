import React, { Component } from 'react'
import { MDBDataTable, MDBBtn, MDBModalBody, MDBModalHeader, MDBModal } from 'mdbreact'
import { getAllArticles, deleteArticle } from '../../Articles/ArticleFunctions'

const QUIZ_columns = [
    {
        label: 'Article Name',
        field: 'id',
        sort: 'asc',
    }
]

let viewError = false
let errorMessage = ''

class ViewQuizList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: {
                columns: QUIZ_columns,
                rows: []
            },
            selectedArticleTitle: '',
            deleteModal: false,
            error: false,
            errorMessage: ''
        }
    }

    modify(id) {
        this.props.history.push('/admin/' + this.state.data.rows[id].title + '/quiz')
    }

    delete(title) {
        this.setState({ selectedArticleTitle: title })
        this.toggleDeleteModal()
    }

    toggleDeleteModal() {
        this.setState({ deleteModal: !this.state.deleteModal })
    }

    deleteArticle() {
        deleteArticle(this.state.selectedArticleTitle).then(response => {
            if (response.status !== 200) {
                this.setState({
                    error: true,
                    errorMessage: 'Cannot delete this article because a user has taken a quiz associated with this article.\n',
                    deleteModal: !this.state.deleteModal
                })
            }
            else {
                alert("Successfully removed article!")
                window.location.reload()
            }
        })
    }

    componentDidMount() {
        if (localStorage.getItem("isAdmin")) {
            getAllArticles().then(response => {
                if (response.status === 200 && response.data !== undefined) {
                    viewError = false
                    errorMessage = ''

                    let quizzes = []

                    for (let i = 0; i < response.data.articles.length; i++) {
                        quizzes[i] = {
                            title: response.data.articles[i].title,
                            update: (
                                <MDBBtn color="primary" size="sm" onClick={() => this.modify(i)}>Modify Quiz</MDBBtn>
                            ),
                            delete: (
                                <MDBBtn color="danger" size="sm" onClick={() => this.delete(response.data.articles[i].title)}>Delete Article</MDBBtn>
                            )
                        }
                    }
                    this.setState({ data: { ...this.state.data, rows: quizzes } })
                }
            })
        }
        else {
            this.props.history.push('/')
        }
    }

    render() {
        const UserMessages = (
            <div>
                <span style={{ color: 'red' }}>
                    {viewError ? errorMessage : ''}
                </span>
            </div>
        )

        const deleteModal = (
            <div className="container">
                <MDBModal isOpen={this.state.deleteModal} toggle={this.toggleDeleteModal.bind(this)}>
                    <MDBModalHeader>
                        Are you sure?
                    </MDBModalHeader>
                    <MDBModalBody>
                        Delete:
                        <br />
                        <strong>{this.state.selectedArticleTitle}</strong>
                        <br />from the database?
                        <p>This action cannot be undone.</p>
                        <MDBBtn color="info" onClick={this.toggleDeleteModal.bind(this)}>Cancel</MDBBtn>
                        <MDBBtn color="danger" onClick={this.deleteArticle.bind(this)}>Delete</MDBBtn>
                    </MDBModalBody>
                </MDBModal>
            </div>
        )

        const error = (
            <span style={{ color: "red" }}>{this.state.errorMessage}</span>
        )

        return (
            <div>
                <h1 className="h1-upward">Manage Articles</h1>
                {this.state.error ? error : ''}
                <MDBDataTable
                    striped
                    bordered
                    hover
                    responsive
                    small
                    data={this.state.data}
                />
                {UserMessages}
                {deleteModal}
            </div>
        )
    }
}

export default ViewQuizList