import React, { Component } from 'react'

class FillInOptions extends Component {
    constructor(props) {
        super(props)
        this.state = {
            options: []
        }
    }

    componentDidMount() {
        if (this.props.options !== undefined) {
            this.setState({ options: this.props.options })
            document.getElementById("option_0").value = this.props.options[0].option
        }
        if (this.props.back === true) {
            this.setState({
                options: []
            })
            this.props.backToggle()
        }
    }

    updateOption(e) {
        let options = this.state.options
        options[0] = {
            option: e.target.value,
            is_answer: true
        }
        this.setState({
            options: options
        })

        this.optionsFilled()
    }

    optionsFilled = () => {
        if (this.state.options[0] === undefined || this.state.options[0].option === '') {
            this.props.optionsFilledCheck(false)
        }
        else {
            this.props.optionsFilledCheck(true)
            this.sendOptions()
        }
    }

    sendOptions = () => {
        this.props.getOptions(this.state.options)
    }

    render() {
        return (
            <div>
                <h3>Answer:</h3>
                <input type="text"
                    size="30"
                    maxLength="200"
                    placeholder="Input Correct Answer..."
                    style={{ marginLeft: "2em" }}
                    onChange={this.updateOption.bind(this)}
                    id="option_0"></input>
            </div>
        )
    }
}

export default FillInOptions