import React, { Component } from 'react'
import { MDBBtn, MDBModalBody, MDBModalHeader, MDBModal, MDBIcon } from 'mdbreact'
import AddQuizQuestion from './AddQuizQuestion'
import { getQuiz, DIFFICULTY_MODIFIERS_NAMES } from '../../Quiz/QuizFunctions'
import Loader from 'react-loader-spinner'
import DeleteQuestion from './DeleteQuestion'
import UpdateQuestion from './UpdateQuestion'

let ADD_A_QUIZ_QUESTION_MESSAGE = 'There are no quiz questions associated with this article.'

class ModifyQuiz extends Component {
    constructor(props) {
        super(props)
        this.state = {
            questions: [],
            showQuizModal: false,
            add: false,
            delete: false,
            update: false,
            userFunction: '',
            loaded: false,
            errorMessage: '',
            successMessage: '',
            selectedQuestion: '',
            selectedQuestionIncludingOptions: {}
        }
    }

    getQuestions() {
        getQuiz(this.props.match.params.title).then(response => {
            if (response.status === 200 && response.data !== undefined) {
                this.setState({
                    questions: response.data.questions,
                    loaded: true
                })
            }
            else if (response.data.message === 'Quiz Questions not found') {
                this.setState({
                    errorMessage: ADD_A_QUIZ_QUESTION_MESSAGE,
                    loaded: true,
                    questions: []
                })
            }
            else {
                this.setState({
                    errorMessage: response.data.message,
                    loaded: true
                })
            }
        })
    }

    componentDidMount() {
        if (localStorage.getItem("isAdmin")) {
            this.props.history.push("/admin/" + this.props.match.params.title + "/quiz")
        }
        else {
            this.props.history.push("/")
        }
    }

    UNSAFE_componentWillMount() {
        if (localStorage.getItem("isAdmin")) {
            this.getQuestions()
        }
        else {
            this.props.history.push('/')
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.questions !== this.state.questions) {
            this.setState({
                loaded: true
            })
        }
    }

    toggleQuizModal() {
        this.setState({
            showQuizModal: !this.state.showQuizModal,
        })

        if(this.state.showQuizModal){
            this.setState({
                add: false,
                delete: false,
                update: false
            })
        }
    }

    componentCloseModal = (type) => {
        this.toggleQuizModal()
        this.getQuestions()
        
        if (type==="add") {
            this.setState({
                add: false,
            })
        }
        if(type==="delete"){
            this.setState({
                delete: false,
            })
        }
        if(type==="update"){
            this.setState({
                update: false,
            })
        }
    }

    addQuestion() {
        this.setState({
            userFunction: 'Add a question',
            add: true
        })
        this.toggleQuizModal()
    }

    deleteQuestion(e) {
        this.setState({
            userFunction: 'Are you sure?',
            delete: true,
            selectedQuestion: [e.target.id]
        })
        this.toggleQuizModal()
    }

    editQuestion(e) {
        this.setState({
            userFunction: 'Edit a question',
            update: true
        })
        for (var i = 0; i < this.state.questions.length; i++) {
            if ([e.target.id].toString() === this.state.questions[i].question) {
                this.setState({
                    selectedQuestionIncludingOptions: this.state.questions[i]
                })
            }
        }
        this.toggleQuizModal()
    }

    render() {
        const displayModal = (
            <div className="container">
                <MDBModal isOpen={this.state.showQuizModal} toggle={this.toggleQuizModal.bind(this)}>
                    <MDBModalHeader>
                        {this.state.userFunction}
                    </MDBModalHeader>
                    <MDBModalBody>
                        {this.state.add ? <AddQuizQuestion
                            toggleModal={this.componentCloseModal}
                            title={this.props.match.params.title} /> : ''}
                        {this.state.delete ? <DeleteQuestion
                            toggleModal={this.componentCloseModal}
                            title={this.props.match.params.title}
                            question={this.state.selectedQuestion} /> : ''}
                        {this.state.update ? <UpdateQuestion
                            toggleModal={this.componentCloseModal}
                            title={this.props.match.params.title}
                            question={this.state.selectedQuestionIncludingOptions} /> : ''}
                    </MDBModalBody>
                </MDBModal>
            </div>
        )

        const loading = (
            <div>
                <br /><br />
                <Loader type="Oval" color="#008fd4" height={80} width={80} />
            </div>
        )

        const questions = (
            <div>
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">Question</th>
                            <th scope="col">Difficulty Modifier</th>
                            <th scope="col">Answer 1</th>
                            <th scope="col">Answer 2</th>
                            <th scope="col">Answer 3</th>
                            <th scope="col">Answer 4</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.questions.map(question =>
                            <>
                                <tr key={question.id}>
                                    <td style={{ textAlign: 'left' }} key={question.question}>
                                        <h4>{question.question}</h4>
                                        <div>
                                            <MDBBtn color="info" id={question.question} onClick={this.editQuestion.bind(this)}>Edit</MDBBtn>
                                            <MDBBtn color="danger" id={question.question} onClick={this.deleteQuestion.bind(this)}>Delete</MDBBtn>
                                        </div>
                                    </td>
                                    <td>{DIFFICULTY_MODIFIERS_NAMES[question.difficulty]}</td>
                                    {question.options.map((option, index) =>
                                        <td key={index}>
                                            {option.is_answer ? <span style={{ color: 'green' }}>{option.option}</span> : <span style={{ color: 'red' }}>{option.option}</span>}
                                        </td>)}
                                </tr>
                                <tr>
                                </tr>
                            </>)}
                    </tbody>
                </table>
                <br />
            </div>
        )

        return (
            <div>
                <h1 className="h1-upward">Modify Quiz for:</h1>
                <h2>{this.props.match.params.title}</h2>
                <span style={{ color: 'red' }}>{this.state.errorMessage}</span>
                <div>
                    <div className="float-right">
                        <small>Add a new question</small><br />
                        <MDBIcon icon="plus" size="4x" onClick={() => this.addQuestion()} className="clickable-icon" />
                    </div>
                    {this.state.loaded ? questions : loading}
                </div>
                {displayModal}
                <br />
            </div>
        )
    }
}

export default ModifyQuiz