import React, { Component } from 'react'
import renderHTML from 'react-render-html'
import { getArticle } from './ArticleFunctions'

let displayError = false
let errorMessage = ''

class Article extends Component {
    constructor(props) {
        super(props)
        this.state = {
            title: '',
            content: ''
        }
    }

    componentDidMount() {
        if (this.props.newestArticleTitle !== undefined) {
            getArticle(this.props.newestArticleTitle).then(response => {
                if (response.status === 200) {
                    this.setState({
                        content: response.data.content,
                        title: response.data.title
                    })
                }
                else {
                    displayError = true
                    errorMessage = response.data.message
                }
            })
        }
        else {
            getArticle(this.props.match.params.title).then(response => {
                if (response.status === 200) {
                    this.setState({
                        content: response.data.content,
                        title: response.data.title
                    })
                    this.props.history.push('/article/' + this.state.title)
                }
                else {
                    displayError = true
                    errorMessage = response.data.message
                    this.props.history.push('/articles')
                }
            })
        }
    }

    render() {
        return (
            <div>
                <h3 className="h3-article">{this.state.title}</h3>
                {renderHTML(this.state.content)}
                <span style={{ color: 'red' }}>
                    {displayError ? errorMessage : ''}
                </span>
            </div>
        )
    }
}

export default Article