import React, { Component } from 'react'

class TrueFalseOptions extends Component {
    constructor(props) {
        super(props)
        this.state = {
            options: []
        }
    }

    componentDidMount() {
        if (this.props.options !== undefined) {
            this.setState({ options: this.props.options })
            for (var i = 0; i < this.props.options.length; i++) {
                document.getElementById("is_answer_" + i).checked = this.props.options[i].is_answer
            }
        }
        if (this.props.back === true) {
            this.setState({
                options: []
            })
            this.props.backToggle()
        }
    }

    updateCorrectAnswer(e) {
        let options = this.state.options
        options[0] = {
            option: 'True',
            is_answer: false
        }
        options[1] = {
            option: 'False',
            is_answer: false
        }
        options[e.target.value].is_answer = true
        this.setState({
            options: options
        })
        this.answerSelectedCheck()
    }

    answerSelectedCheck = () => {
        this.props.answerSelectedCheck(true)
        this.sendOptions()
    }

    sendOptions = () => {
        this.props.getOptions(this.state.options)
    }

    render() {
        return (
            <div>
                <input type="radio"
                    name="indexOfCorrectAnswer"
                    value="0"
                    onChange={this.updateCorrectAnswer.bind(this)}
                    id="is_answer_0" />
                &nbsp;True <br />
                <input type="radio"
                    name="indexOfCorrectAnswer"
                    value="1"
                    onChange={this.updateCorrectAnswer.bind(this)}
                    id="is_answer_1" />
                &nbsp;False
                <br />
                <small>Select which is the correct answer.</small>
            </div>
        )
    }
}

export default TrueFalseOptions