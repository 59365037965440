import React, { Component } from 'react'
import { getAllArticles } from './ArticleFunctions'
import { MDBDataTable } from 'mdbreact'
import Loader from 'react-loader-spinner'
import Article from './Article'

const columns = [
    {
        label: 'Title',
        field: 'title',
        sort: 'asc'
    },
    {
        label: 'Date Published',
        field: 'create_dt',
        sort: 'desc'
    },
]

let viewError = false
let errorMessage = ''

class ViewArticles extends Component {
    constructor(props) {
        super(props)
        this.state = {
            articles: [{
                title: '',
                create_dt: ''
            }],
            loaded: false,
            articleToLoad: ''
        }
    }

    redirectToArticle(articleTitle) {
        this.props.history.push('/articles/' + articleTitle)
        window.location.reload()
    }

    removeSearchLabel() {
        for (var s = 0; s < document.getElementsByTagName('label').length; s++) {
            document.getElementsByTagName('label')[s].setAttribute('id', 'remove_' + s)
        }
        document.getElementById('remove_1').childNodes[0].textContent = ''
    }

    componentDidMount() {
        getAllArticles().then(response => {
            if (response.status === 200 || response.data !== undefined) {
                viewError = false
                errorMessage = ''

                let articles = []
                for (var i = 0; i < response.data.articles.length; i++) {
                    let titleLink = response.data.articles[i].title
                    articles[i] = {
                        title: response.data.articles[i].title,
                        create_dt: response.data.articles[i].create_dt,
                        clickEvent: () => this.redirectToArticle(titleLink)
                    }
                }
                if (this.props.match.params.title !== undefined) {
                    this.setState({ articleToLoad: this.props.match.params.title })
                }
                else {
                    this.setState({ articleToLoad: articles[0].title })
                }
                this.setState({ articles: articles })
                this.setState({ loaded: true })
            }
            else {
                viewError = true
                errorMessage = response.data.message
                this.props.history.push('/articles')
            }
        })
        this.removeSearchLabel()
    }

    render() {
        const renderAfterCall = (
            <div>
                <Article newestArticleTitle={this.state.articleToLoad}></Article>
            </div>
        )

        const loading = (
            <div>
                <br /><br />
                <Loader type="Oval" color="#008fd4" height={80} width={80} />
            </div>
        )

        return (
            <div>
                <span style={{ color: 'red' }}>
                    {viewError ? errorMessage : ''}
                </span>
                <div className="row">
                    <div className="col-sm">
                        {this.state.loaded ? renderAfterCall : loading}
                        <br />
                        <h4>Check out other articles below or head to <a href="/quiz">Quiz Yourself</a>!</h4>
                        <br />
                        <MDBDataTable
                            striped
                            bordered
                            hover
                            responsive
                            small
                            className="articles-table article-div"
                            data={{ columns: columns, rows: this.state.articles }}
                            order={['create_dt', 'desc']}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default ViewArticles