import React, { Component } from 'react'
import './Quiz.css';
import { MDBContainer, MDBInput, MDBBtn } from 'mdbreact'
import { getQuiz, getAllQuizzes, submitQuizResult, getRandomPositiveResponse, getRandomNegativeResponse, getRandomHighScoreResponse, getRandomLowScoreResponse } from './QuizFunctions'

let quizError = false
let quizErrorMessage = ''
let quizAnswerMessage = ''
let quizResultMessage = ''
let quizScoreMessage = ''
const quizAlreadyTakenMessage = 'This score will not be saved since you have already taken this quiz.'

class Quiz extends Component {

    constructor(props) {
        super(props)
        this.state = {
            quizzes: '',
            curQuiz: '',
            questions: [],
            question: '',
            question_id: 0,
            question_type: '',
            options: [],
            selectedAnswer: '',
            initialState: true,
            questionState: false,
            answerState: false,
            resultState: false,
            quizResult: [],
            loaded: false,
            count: 0,
            countCorrect: 0,
            secondsRemaining: 0,
            seconds: process.env.REACT_APP_TIMER_SECONDS,
            outOfTime: false,
            score: 0,
            answer_feedback: '',
            score_feedback: ''
        };

        this.onChange = this.onChange.bind(this)
    }

    componentDidMount() {
        if (localStorage.getItem("usertoken") === null) {
            this.props.history.push('/login')
        }
        getAllQuizzes().then(response => {
            if (response.status === 200) {
                quizError = false
                quizErrorMessage = ''

                let quizzes = []
                for (var i = 0; i < response.data.quizzes.length; i++) {
                    quizzes[i] = response.data.quizzes[i].title
                }

                let firstQuiz = response.data.quizzes[0].title

                this.setState({ quizzes: quizzes, curQuiz: firstQuiz })

                if (this.state.curQuiz === null || this.state.curQuiz === "") {
                    quizError = true;
                    quizErrorMessage = "There is no quiz available"
                    document.getElementById("startBtn").disabled = true
                    this.props.history.push('/quiz')
                }
            }
            else {
                quizError = true
                quizErrorMessage = response.data.message
                document.getElementById("startBtn").disabled = true
                this.props.history.push('/quiz')
            }
        })
    }

    startTimer() {
        this.setState({
            secondsRemaining: this.state.seconds
        })

        this.timer = setInterval(() => {
            const seconds = this.state.seconds

            if (seconds > 0) {
                this.setState({
                    seconds: seconds - 1,
                    secondsRemaining: seconds - 1
                })
            }

            if (seconds === 0) {
                clearInterval(this.timer)
                this.setState({
                    seconds: process.env.REACT_APP_TIMER_SECONDS,
                    outOfTime: true
                })
            }

        }, 1000)
    }

    resetTimer() {
        if (!this.state.outOfTime) {
            clearInterval(this.timer)
            this.setState({
                seconds: process.env.REACT_APP_TIMER_SECONDS
            })
        }

        this.setState({
            outOfTime: false
        })
    }

    startQuiz = () => {
        document.getElementById("curQuiz").disabled = true
        getQuiz(this.state.curQuiz).then(response => {
            if (response.status === 200) {
                this.setState({
                    questions: response.data.questions,
                    question: response.data.questions[this.state.count].question,
                    question_id: response.data.questions[this.state.count].question_id,
                    question_type: response.data.questions[this.state.count].question_type,
                    options: response.data.questions[this.state.count].options,
                    count: this.state.count + 1
                })

                if (this.state.questions === null || this.state.questions.length === 0 || this.state.question === "") {
                    quizError = true
                    quizErrorMessage = "This article doesn't have a quiz"
                    this.props.history.push('/quiz')
                }
            }
            else {
                quizError = true
                quizErrorMessage = response.data.message
                this.props.history.push('/quiz')
            }
        })

        this.setState({
            initialState: false,
            questionState: true
        })

        this.startTimer()
    }

    submitAnswer = () => {
        this.resetTimer()
        this.setState({
            questionState: false,
            answerState: true
        })
        this.checkAnswer()
    }

    checkAnswer = () => {
        let isCorrect = false
        let ans = ''
        for (let i = 0; i < this.state.options.length; i++) {
            if (this.state.options[i].is_answer === 1) {
                ans = this.state.options[i].option
            }
        }

        if (this.state.question_type === 0 || this.state.question_type === 1) {
            if (this.state.selectedAnswer === ans) {
                isCorrect = true
                quizAnswerMessage = "Your answer is correct."
                this.setState({ answer_feedback: getRandomPositiveResponse() })
                this.setState({
                    countCorrect: this.state.countCorrect + 1
                })
            } else {
                quizAnswerMessage = "Your answer is incorrect. The correct answer is \"" + ans + "\"."
                this.setState({ answer_feedback: getRandomNegativeResponse() })
            }
        } else if (this.state.question_type === 2) {
            if (this.state.selectedAnswer.toLowerCase() === ans.toLowerCase()) {
                isCorrect = true
                quizAnswerMessage = "Your answer is correct."
                this.setState({ answer_feedback: getRandomPositiveResponse() })
                this.setState({
                    countCorrect: this.state.countCorrect + 1
                })
            } else {
                quizAnswerMessage = "Your answer is incorrect. The correct answer is \"" + ans + "\"."
                this.setState({ answer_feedback: getRandomNegativeResponse() })
            }
        }

        this.setState(prevState => ({
            quizResult: [...prevState.quizResult,
            {
                question_id: this.state.question_id,
                correct: isCorrect,
                time_left: parseInt(this.state.secondsRemaining)
            }]
        }))
    }

    nextQuestion = () => {
        if (this.state.count < this.state.questions.length) {
            this.startTimer()
            this.setState({
                questionState: true,
                answerState: false,
                question: this.state.questions[this.state.count].question,
                question_id: this.state.questions[this.state.count].question_id,
                question_type: this.state.questions[this.state.count].question_type,
                options: this.state.questions[this.state.count].options,
                selectedAnswer: '',
                count: this.state.count + 1
            })
        } else {
            this.setState({
                answerState: false,
                resultState: true
            })
            this.calculateScore()
        }
    }

    resultDisplay = () => {
        quizResultMessage = "You answered correctly " + this.state.countCorrect + " out of " + this.state.count + " questions."
        quizScoreMessage = "Your score is " + this.state.score + "!";
        if (this.state.score > process.env.REACT_APP_QUIZ_SCORE_FOR_GIFS) {
            this.setState({ score_feedback: getRandomHighScoreResponse() })
        }
        else {
            this.setState({ score_feedback: getRandomLowScoreResponse() })
        }
    }

    calculateScore = () => {
        const result = {
            title: this.state.curQuiz,
            results: this.state.quizResult
        }

        submitQuizResult(result).then(response => {
            if (response.status === 200) {
                this.setState(
                    {
                        score: response.data.score,
                        quizAlreadyTaken: response.data.quizAlreadyTaken
                    },
                    this.resultDisplay
                )
            }
            else {
                quizError = true
                quizErrorMessage = response.data.message
            }
            this.props.history.push('/quiz')
        })
    }

    redirectToArticle = () => {
        this.props.history.push('/articles')
    }

    redirectToQuiz = () => {
        window.location.reload()
    }

    onChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            this.submitAnswer()
        }
    }

    render() {

        const gifGoodScore = (
            <div className="viaGiphy">
                <iframe src="https://giphy.com/embed/fxsqOYnIMEefC" title="well-done" frameBorder="0" className="giphy-embed gif" allowFullScreen /><br /><a href="https://giphy.com/gifs/well-done-fxsqOYnIMEefC">via GIPHY</a>
            </div>
        )

        const gifBadScore = (
            <div className="viaGiphy">
                <iframe src="https://giphy.com/embed/NTY1kHmcLsCsg" title="sad-frown-cartoon" frameBorder="0" className="giphy-embed gif" allowFullScreen></iframe><br /><a href="https://giphy.com/gifs/sad-frown-cartoon-NTY1kHmcLsCsg">via GIPHY</a>
            </div>
        )

        const quizErrorRender = (
            <div>
                {quizErrorMessage}
            </div>
        )

        let quizzesArr = []
        for (let k = 0; k < this.state.quizzes.length; k++) {
            quizzesArr[k] = (<option key={this.state.quizzes[k]}>{this.state.quizzes[k]}</option>)
        }

        let optionsArr = []
        if (this.state.question_type === 0 || this.state.question_type === 1) {
            for (let i = 0; i < this.state.options.length; i++) {
                optionsArr[i] = (<div key={this.state.options[i].option} style={{ textAlign: "left", margin: "auto", paddingBottom: 5 }}>
                    <input type="radio" name="selectedAnswer"
                        value={this.state.options[i].option}
                        checked={this.state.selectedAnswer === this.state.options[i].option}
                        onChange={this.onChange} onKeyPress={this.handleKeyPress} />
                    <span style={{ paddingLeft: 10 }}>{this.state.options[i].option}</span> <br />
                </div>)
            }
        } else if (this.state.question_type === 2) {
            optionsArr[0] = (<div style={{ textAlign: "left" }}>
                <MDBInput name="selectedAnswer" label="Type your answer here" value={this.state.selectedAnswer} onChange={this.onChange} onKeyPress={this.handleKeyPress} />
            </div>)
        }

        const initialRender = (
            <div>
                <div>
                    <h4 style={{ textAlign: "left" }}>Test your comprehension with timed quiz questions.</h4>
                    <br />
                    <p style={{ textAlign: "left" }}>
                        ✔ The faster you answer correctly, the better your score!<br />
                        ✔ You can take the quiz as many times as you want.<br />
                        ✔ Do well and you have a chance of being featured on the public Rankings page.<br />
                        ✔ Only the quiz score from the FIRST time you take it will count toward your public ranking.<br />
                    </p>
                </div>
                <br />
                <MDBBtn id="startBtn" color="blue" type="submit" className="btn btn-primary" onClick={this.startQuiz}>
                    Begin the quiz
                </MDBBtn>
            </div>
        )

        const questionRender = (
            <div>
                <div className="customContainer">
                    <div>
                        <h5 className="question"><b> {this.state.question} </b></h5>
                        <h6 className="timer">timer: {this.state.secondsRemaining}s</h6>
                    </div>
                    <div className="options">
                        {optionsArr}
                    </div>
                    <div>
                        <MDBBtn color="primary" type="button" className="btn btn-primary mb-2 submitBtn" onClick={this.submitAnswer}>
                            Submit
                        </MDBBtn>
                    </div>
                </div>
                <div className="count">{this.state.count}/{this.state.questions.length}</div>
            </div>
        )

        const answerRender = (
            <div>
                <div className="customContainer">
                    <div>
                        <h5 className="question"><b> {this.state.question} </b></h5>
                        <h6 className="timer">timer: {this.state.secondsRemaining}s</h6>
                    </div>
                    <div className="answer">
                        <h4 className="feedback">{this.state.answer_feedback}</h4><br />
                        <h7>{quizAnswerMessage}</h7>
                        <div>
                            <MDBBtn color="primary" type="button"
                                className="btn btn-primary mb-2 nextBtn"
                                onClick={this.nextQuestion}>
                                Continue
                            </MDBBtn>
                        </div>
                    </div>
                </div>
                <div className="count">{this.state.count}/{this.state.questions.length}</div>
            </div>
        )

        const resultRender = (
            <div>
                <div>
                    {quizResultMessage}
                </div>
                <div>
                    <strong>{quizScoreMessage}</strong>
                    <h5 className="feedback">{this.state.score_feedback}</h5>
                </div>
                {this.state.score > process.env.REACT_APP_QUIZ_SCORE_FOR_GIFS ? gifGoodScore : ''}
                {this.state.score < process.env.REACT_APP_QUIZ_SCORE_FOR_GIFS ? gifBadScore : ''}
                <div>
                    <br />
                    <i style={{ color: "#008fd4", fontWeight: "bold" }}>{this.state.quizAlreadyTaken ? quizAlreadyTakenMessage : ''}</i>
                </div>
                <div style={{ marginTop: 20 }}>
                    <MDBBtn color="primary" type="button"
                        className="btn btn-primary mb-2"
                        onClick={this.redirectToArticle}>
                        Back to Articles
                    </MDBBtn>
                    <MDBBtn color="primary" type="button"
                        className="btn btn-primary mb-2"
                        onClick={this.redirectToQuiz}>
                        Back to Quizzes
                    </MDBBtn>
                </div>
            </div>
        )

        const quiz_selection = (
            <div>
                {this.state.initialState ? <h6 className="selection">Select which quiz you want to take</h6> : ''}
                <select className="browser-default custom-select" name="curQuiz" id="curQuiz" onChange={this.onChange} value={this.state.value}>
                    {quizzesArr}
                </select>
            </div>
        )

        return (
            <MDBContainer>
                <h1 className="h1-upward">Quiz</h1>
                {this.state.initialState ? quiz_selection : ''}
                <div className="stateContainer">
                    {this.state.initialState ? initialRender : ''}
                    {this.state.questionState ? questionRender : ''}
                    {this.state.answerState ? answerRender : ''}
                    {this.state.resultState ? resultRender : ''}
                    <div>
                        <span style={{ color: 'red' }}>
                            {quizError ? quizErrorRender : ''}
                        </span>
                    </div>
                </div>
            </MDBContainer>
        );
    }
}

export default Quiz