import React, { Component } from 'react'
import { MDBContainer, MDBInput, MDBBtn } from "mdbreact"
import { forgotUsername } from '../UserFunctions'

const successMsg = 'Email sent!';
var errorMsg = ''

class ForgotUsername extends Component {
    constructor(props) {
        super(props)
        this.state = {
            success: false,
            error: false,
            email: '',
        }
        this.onSubmit = this.onSubmit.bind(this)
        this.onChange = this.onChange.bind(this)
    }

    async onSubmit(e) {
        e.preventDefault();
        forgotUsername(this.state.email).then(res => {
            if (res.status === 200) {
                this.setState({success: true, error: false})
            }
            else {
                errorMsg = res.data.message;
                this.setState({error: true})
            }
        })
    }

    onChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    render() {
        return (
            <div>
                <MDBContainer>
                    <form className="mx-auto custom-form" onSubmit={this.onSubmit}>
                        <h1> Forgot Username </h1>
                        <br></br>
                        <small className="form-text text-muted password-description">
                            Enter the email address associated with your account. An email will be sent with the username associated with that email address.
                        </small>
                        <MDBInput type="email" name="email" label="Email" value={this.state.email} onChange={this.onChange} required />
                        <MDBBtn type="submit" color="primary"> Submit </MDBBtn>
                        <div>
                            <span style={{ color: 'red' }}>
                                {this.state.error ? errorMsg : ''}
                            </span>
                            <span style={{ color: 'green' }}>
                                {this.state.success ? successMsg : ''}
                            </span>
                        </div>
                    </form>
                </MDBContainer>
            </div>
        );
    }
}

export default ForgotUsername