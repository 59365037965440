import React, { Component } from 'react'
import { MDBIcon, MDBDropdown, MDBDropdownItem, MDBDropdownToggle, MDBDropdownMenu } from 'mdbreact'
import { withRouter } from 'react-router-dom'
import './Navbar.css'

class Navbar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isAdmin: false,
      show: false
    }
  }

  logout(e) {
    e.preventDefault()
    localStorage.clear()
    this.props.callBackFromParent()
    this.props.history.push('/')
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isAdmin !== this.props.isAdmin) {
      this.setState({ isAdmin: true })
    }
  }

  toggleMenu() {
    this.setState({ show: !this.state.show })
  }

  render() {
    const adminNav = (
      <li className="nav-item">
        <MDBDropdown>
          <MDBDropdownToggle className="nav-link" tag="p" color="white" >
            <MDBIcon icon="cog" className="white-text clickable-icon" size="lg" />
          </MDBDropdownToggle>
          <MDBDropdownMenu>
            <MDBDropdownItem><a href="/users">Users</a></MDBDropdownItem>
            <MDBDropdownItem><a href="/upload">Upload New Article</a></MDBDropdownItem>
            <MDBDropdownItem><a href="/quizzes">Manage Articles</a></MDBDropdownItem>
          </MDBDropdownMenu>
        </MDBDropdown>
      </li>
    )

    const nonuserNav = (
      <ul className="navbar-nav">
        <li className="nav-item">
          <a className="nav-link" href="/"><strong>Home</strong></a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="/login">Login</a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="/register">Register</a>
        </li>
      </ul>
    )

    const userNav = (
      <ul className="navbar-nav">
        <li className="nav-item">
          <MDBDropdown>
            <MDBDropdownToggle className="nav-link" tag="p" color="white" >
              <MDBIcon icon="user" className="white-text clickable-icon" size="lg" />
            </MDBDropdownToggle>
            <MDBDropdownMenu>
              <MDBDropdownItem><a href={"../profile/" + localStorage.getItem('userName')}>Public Profile</a></MDBDropdownItem>
              <MDBDropdownItem><a href="/account">Account Details</a></MDBDropdownItem>
              <MDBDropdownItem><a href="/password">Change Password</a></MDBDropdownItem>
              <MDBDropdownItem><a href="/" onClick={this.logout.bind(this)}>Logout</a></MDBDropdownItem>
            </MDBDropdownMenu>
          </MDBDropdown>
        </li>
        {this.props.isAdmin ? adminNav : ''}
        <li className="nav-item">
          <a className="nav-link" href="/"><strong>Home</strong></a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="/articles">Learn</a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="/quiz">Quiz</a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="/rankings">Rankings</a>
        </li>
      </ul>
    )
    const show = (this.state.show) ? "show" : ""

    return (
      <div>
        <nav className="navbar navbar-expand-md navbar-dark black">
          <button className="navbar-toggler" type="button" onClick={this.toggleMenu.bind(this)}>
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className={"collapse navbar-collapse " + show} id="collapsibleNavbar">
            {localStorage.usertoken ? userNav : nonuserNav}
          </div>
        </nav>
      </div>
    )
  }
}

export default withRouter(Navbar)