import React, { Component } from 'react'
import { MDBContainer, MDBInput, MDBBtn } from 'mdbreact'
import './Form.css';
import { login } from '../UserFunctions'

var loginError = false
let loginErrorMessage = ''

class Login extends Component {

    constructor(props) {
        super(props)
        this.state = {
            userName: '',
            password: '',
        };

        this.onSubmit = this.onSubmit.bind(this)
        this.onChange = this.onChange.bind(this)
    }

    onSubmit(e) {
        e.preventDefault();

        const user = {
            userName: this.state.userName,
            password: this.state.password,
        }

        login(user).then(res => {
            if (res.status === 200) {
                this.props.callBackFromParent("login")
                loginError = false;
                this.props.history.push('/articles')
                return;
            }
            else {
                loginError = true;
                loginErrorMessage = res.data.message;
                this.props.history.push('/login')
            }
        })
    }

    onChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    componentDidMount() {
        loginError = false
        if (localStorage.getItem("usertoken") === null) {
            this.props.history.push('/login')
        }
        else {
            this.props.history.push('/')
        }
    }

    render() {

        const LoginErrorRender = (
            <div>
                {loginErrorMessage}
            </div>
        )

        return (
            <MDBContainer>
                <form className="text-center border p-5 mx-auto custom-form" onSubmit={this.onSubmit}>
                    <h1 className="h1-upward"> Welcome </h1>
                    <br />
                    <div style={{ lineHeight: ".00001rem" }}>
                        <MDBInput containerClass="noMarg" name="userName" label="Username" value={this.state.userName} onChange={this.onChange} />
                        <div style={{ textAlign: 'right' }}>
                            <small>
                                <a href="/forgotusername">Forgot Username?</a>
                            </small>
                        </div>
                    </div>
                    <br />
                    <div style={{ lineHeight: ".001rem" }}>
                        <MDBInput containerClass="noMarg" type="password" name="password" label="Password" value={this.state.password} onChange={this.onChange} />
                        <div style={{ textAlign: 'right' }}>
                            <small>
                                <a href="/forgotpassword">Forgot Password?</a>
                            </small>
                        </div>
                    </div>
                    <br />
                    <MDBBtn type="submit" color="primary"> Sign in </MDBBtn>
                    <div>
                        <span style={{ color: 'red' }}>
                            {loginError ? LoginErrorRender : ''}
                        </span>
                    </div>
                </form>
            </MDBContainer>
        );
    }
}

export default Login