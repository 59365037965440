import React, { Component } from 'react'
import { MDBModalFooter } from 'mdbreact'
import { addQuestion, QUESTION_TYPE_TRUE_FALSE, QUESTION_TYPE_MULTIPLE_CHOICE, QUESTION_TYPE_FILL_IN } from '../../Quiz/QuizFunctions'
import MultipleChoiceOptions from './Options/MultipleChoiceOptions'
import TrueFalseOptions from './Options/TrueFalseOptions'
import FillInOptions from './Options/FillInOptions'
import DifficultyOption from './Options/DifficultyOption'

class AddQuizQuestion extends Component {
    constructor(props) {
        super(props)
        this.state = {
            questionToSend: {
                question: '',
                question_type: -1,
                options: [],
                difficulty: 0
            },
            back: false,
            optionsFilled: false,
            questionTypeChosen: false,
            answerSelectedCheck: false,
            errorMessage: '',
        }

        this.cancel = this.cancel.bind(this)
    }

    showQuizOptions(e) {
        let type = parseInt([e.target.name])
        this.setState({
            questionTypeChosen: !this.state.questionTypeChosen,
            questionToSend: {
                ...this.state.questionToSend,
                question_type: type
            }
        })

        if (type === QUESTION_TYPE_TRUE_FALSE) {
            this.setState({
                optionsFilled: true
            })
        }

        if (type === QUESTION_TYPE_FILL_IN) {
            this.setState({
                answerSelectedCheck: true
            })
        }
    }

    cancel() {
        this.props.toggleModal()
    }

    AddAQuestion() {
        addQuestion(this.props.title, this.state.questionToSend).then(response => {
            if (response.status === 200) {
                this.setState({ errorMessage: response.data.message })
                this.props.toggleModal("add")
            }
            else {
                this.setState({ errorMessage: response.data.message })
            }
        })
    }

    questionOnChange(e) {
        this.setState({
            questionToSend: {
                ...this.state.questionToSend,
                question: e.target.value
            }
        })
    }

    optionsFilledCheck = (filled) => {
        this.setState({
            optionsFilled: filled
        })
    }

    answerSelectedCheck = (isSelected) => {
        this.setState({
            answerSelectedCheck: isSelected
        })
    }

    updateOptions = (options) => {
        this.setState({
            questionToSend: {
                ...this.state.questionToSend,
                options: options
            }
        })
    }

    goBack(e) {
        this.setState({
            questionToSend: {
                ...this.state.questionToSend,
                question_type: -1,
                options: [],
                difficulty: 0
            },
            back: true,
            questionTypeChosen: false,
            optionsFilled: false,
            answerSelectedCheck: false
        })
    }

    backToggle = () => {
        this.setState({
            back: false
        })
    }

    updateDifficulty = (difficulty) => {
        this.setState({
            questionToSend: {
                ...this.state.questionToSend,
                difficulty: parseInt(difficulty)
            }
        })
    }

    render() {
        const chooseQuizType = (
            <div className="md-form">
                <button className="btn-upward btn-lg"
                    onClick={this.showQuizOptions.bind(this)}
                    name={QUESTION_TYPE_MULTIPLE_CHOICE}
                    disabled={(this.state.questionToSend.question === '')}>
                    Multiple Choice
                </button><br />
                <button className="btn-upward btn-lg"
                    onClick={this.showQuizOptions.bind(this)}
                    name={QUESTION_TYPE_TRUE_FALSE}
                    disabled={(this.state.questionToSend.question === '')}>
                    True/False
                </button><br />
                <button className="btn-upward btn-lg"
                    onClick={this.showQuizOptions.bind(this)}
                    name={QUESTION_TYPE_FILL_IN}
                    disabled={(this.state.questionToSend.question === '')}>
                    Fill in the blank
                </button><br />
            </div>
        )

        const quizTypeMultipleChoice = (
            <div className="md-form">
                <MultipleChoiceOptions
                    optionsFilledCheck={this.optionsFilledCheck}
                    answerSelectedCheck={this.answerSelectedCheck}
                    getOptions={this.updateOptions}
                    backToggle={this.backToggle}
                    back={this.state.back} />
            </div>
        )

        const quizTypeTrueFalse = (
            <div className="md-form">
                <TrueFalseOptions
                    optionsFilledCheck={this.optionsFilledCheck}
                    answerSelectedCheck={this.answerSelectedCheck}
                    getOptions={this.updateOptions}
                    backToggle={this.backToggle}
                    back={this.state.back} />
            </div>
        )

        const quizTypeFillIn = (
            <div>
                <br />
                <FillInOptions
                    optionsFilledCheck={this.optionsFilledCheck}
                    getOptions={this.updateOptions}
                    backToggle={this.backToggle}
                    back={this.state.back} />
            </div>
        )

        return (
            <div>
                <form>
                    <div className="md-form">
                        <h3>Question:</h3>
                        <input type="text"
                            id="question"
                            name="question"
                            size="35"
                            maxLength="500"
                            onChange={this.questionOnChange.bind(this)}
                            required
                            placeholder="Type Your Question..." />
                    </div>
                    <DifficultyOption
                        getDifficulty={this.updateDifficulty}
                        difficulty={this.state.questionToSend.difficulty}
                        backToggle={this.backToggle}
                        back={this.state.back} />
                    {this.state.questionTypeChosen ? '' : chooseQuizType}
                    {this.state.questionToSend.question_type === QUESTION_TYPE_MULTIPLE_CHOICE ? quizTypeMultipleChoice : ''}
                    {this.state.questionToSend.question_type === QUESTION_TYPE_TRUE_FALSE ? quizTypeTrueFalse : ''}
                    {this.state.questionToSend.question_type === QUESTION_TYPE_FILL_IN ? quizTypeFillIn : ''}
                    {this.state.questionTypeChosen ? <button type="button" className="btn btn-warning" onClick={this.goBack.bind(this)}>Back</button> : ''}
                    <span style={{ color: 'red' }}>{this.state.errorMessage}</span>
                    <MDBModalFooter>
                        <button type="button" className="btn-danger btn-lg" onClick={this.cancel.bind(this)}>
                            Cancel
                        </button>
                        <button type="button" className="btn-upward btn-lg" onClick={this.AddAQuestion.bind(this)}
                            disabled={!this.state.optionsFilled || !this.state.answerSelectedCheck || (this.state.questionToSend.question === '')}>
                            Confirm
                        </button>
                    </MDBModalFooter>
                </form>
            </div>
        )
    }
}

export default AddQuizQuestion