import React, { Component } from 'react'
import { MDBModalFooter } from 'mdbreact'
import { updateQuestion, QUESTION_TYPE_TRUE_FALSE, QUESTION_TYPE_MULTIPLE_CHOICE, QUESTION_TYPE_FILL_IN } from '../../Quiz/QuizFunctions'
import MultipleChoiceOptions from './Options/MultipleChoiceOptions'
import TrueFalseOptions from './Options/TrueFalseOptions'
import FillInOptions from './Options/FillInOptions'
import DifficultyOption from './Options/DifficultyOption'

class UpdateQuestion extends Component {
    constructor(props) {
        super(props)
        this.state = {
            updateQuestionToSend: {
                question: '',
                question_type: -1,
                options: [],
                difficulty: 0
            },
            oldQuestion: '',
            optionsFilled: true,
            answerSelectedCheck: true,
            errorMessage: ''
        }
    }

    UNSAFE_componentWillMount() {
        this.setState({
            updateQuestionToSend: this.props.question,
            oldQuestion: this.props.question.question
        })
    }

    componentDidMount() {
        document.getElementById("question").value = this.props.question.question
    }

    cancel() {
        this.props.toggleModal()
    }

    update() {
        updateQuestion(this.props.title, this.state.oldQuestion, this.state.updateQuestionToSend).then(response => {
            if (response.status === 200) {
                this.setState({ errorMessage: response.data.message })
                this.props.toggleModal("update")
            }
            else {
                this.setState({ errorMessage: response.data.message })
            }
        })
    }

    changeQuestion(e) {
        this.setState({
            updateQuestionToSend: {
                ...this.state.updateQuestionToSend,
                question: e.target.value
            }
        })
    }

    optionsFilledCheck = (filled) => {
        this.setState({
            optionsFilled: filled
        })
    }

    answerSelectedCheck = (isSelected) => {
        this.setState({
            answerSelectedCheck: isSelected
        })
    }

    updateOptions = (options) => {
        this.setState({
            updateQuestionToSend: {
                ...this.state.updateQuestionToSend,
                options: options
            }
        })
    }

    updateDifficulty = (difficulty) => {
        this.setState({
            updateQuestionToSend: {
                ...this.state.updateQuestionToSend,
                difficulty: parseInt(difficulty)
            }
        })
    }

    render() {
        const multipleChoiceEdit = (
            <div>
                <MultipleChoiceOptions
                    optionsFilledCheck={this.optionsFilledCheck}
                    answerSelectedCheck={this.answerSelectedCheck}
                    getOptions={this.updateOptions}
                    options={this.state.updateQuestionToSend.options} />
            </div>
        )

        const trueFalseEdit = (
            <div>
                <TrueFalseOptions
                    optionsFilledCheck={this.optionsFilledCheck}
                    answerSelectedCheck={this.answerSelectedCheck}
                    getOptions={this.updateOptions}
                    options={this.state.updateQuestionToSend.options} />
            </div>
        )

        const fillInBlankEdit = (
            <div>
                <FillInOptions
                    optionsFilledCheck={this.optionsFilledCheck}
                    getOptions={this.updateOptions}
                    options={this.state.updateQuestionToSend.options} />
            </div>
        )

        return (
            <div>
                <form>
                    <h3>Question:</h3>
                    <input className="form-control"
                        type="text"
                        onChange={this.changeQuestion.bind(this)}
                        id="question" /><br />
                    <DifficultyOption
                        getDifficulty={this.updateDifficulty}
                        difficulty={this.state.updateQuestionToSend.difficulty} />
                    <br />
                    {this.state.updateQuestionToSend.question_type === QUESTION_TYPE_MULTIPLE_CHOICE ? multipleChoiceEdit : ''}
                    {this.state.updateQuestionToSend.question_type === QUESTION_TYPE_TRUE_FALSE ? trueFalseEdit : ''}
                    {this.state.updateQuestionToSend.question_type === QUESTION_TYPE_FILL_IN ? fillInBlankEdit : ''}
                    <span style={{ color: 'red' }}>{this.state.errorMessage}</span>
                </form>
                <MDBModalFooter>
                    <button type="cancel" className="btn-danger btn-lg" onClick={this.cancel.bind(this)}>
                        Cancel
                        </button>
                    <button type="button" className="btn-upward btn-lg" onClick={this.update.bind(this)}
                        disabled={!this.state.optionsFilled ||
                            !this.state.answerSelectedCheck ||
                            (this.state.updateQuestionToSend.question === '')}>
                        Update
                        </button>
                </MDBModalFooter>
            </div>
        )
    }
}

export default UpdateQuestion