import * as EmailValidator from 'email-validator';

export const testPasswordStrength = async (password, conPassword, messageVar) => {
    let strengthValue = {
        'caps': false,
        'length': false,
        'special': false,
        'numbers': false,
        'small': false
    };

    if (password !== conPassword) {
        alert(messageVar + "s don't match");
        return false;
    }
    else if (password.length < 6) {
        alert(messageVar + " is too short!");
        return false;
    }
    else {
        strengthValue.length = true;
    }

    for (let index = 0; index < password.length; index++) {
        let char = password.charCodeAt(index);
        if (!strengthValue.caps && char >= 65 && char <= 90) {
            strengthValue.caps = true;
        } else if (!strengthValue.numbers && char >= 48 && char <= 57) {
            strengthValue.numbers = true;
        } else if (!strengthValue.small && char >= 97 && char <= 122) {
            strengthValue.small = true;
        } else if (!strengthValue.special && ((char >= 33 && char <= 47) || (char >= 58 && char <= 64))) {
            strengthValue.special = true;
        }
    }

    var strengthIndicator = 0;

    for (var metric in strengthValue) {
        if (strengthValue[metric] === true) {
            strengthIndicator++;
        }
    }

    if (strengthIndicator !== 5) {
        alert(messageVar + " is not strong enough!");
        return false;
    }

    return true;
}

export const testValidEmail = async (email) => {
    if (EmailValidator.validate(email)) {
        return true;
    }
    else {
        alert("Please enter a valid email");
        return false;
    }
}