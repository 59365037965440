import React, { Component } from 'react'
import { MDBModalFooter } from 'mdbreact'
import { deleteQuestion } from '../../Quiz/QuizFunctions'

class DeleteQuestion extends Component {
    constructor(props) {
        super(props)
        this.state = {
            question: props.question,
            errorMessage: ''
        }
    }

    deleteQuestion() {
        deleteQuestion(this.props.title, this.state.question).then(response => {
            if (response.status === 200) {
                this.setState({ errorMessage: response.data.message })
                this.props.toggleModal("delete")
            } else {
                this.setState({ errorMessage: response.data.message })
            }
        })
    }

    cancel() {
        this.props.toggleModal()
    }

    render() {
        return (
            <div>
                <p>You are about to delete question: <br /><span style={{ fontWeight: 'bold', fontSize: '20px' }}>"{this.state.question}"</span> from {this.props.title}'s quiz.</p>
                <span style={{ fontWeight: 'bold' }}>This action cannot be undone.</span>
                <span style={{ color: 'red' }}>{this.state.errorMessage}</span>
                <MDBModalFooter>
                    <button type="cancel" className="btn-danger btn-lg" onClick={this.cancel.bind(this)}>
                        Cancel
                    </button>
                    <button type="button" className="btn-upward btn-lg" onClick={this.deleteQuestion.bind(this)}>
                        Confirm
                    </button>
                </MDBModalFooter>
            </div>
        )
    }
}

export default DeleteQuestion