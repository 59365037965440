import React, { Component } from 'react'
import { MDBContainer, MDBInput, MDBBtn } from "mdbreact"
import { changeForgotPassword } from '../UserFunctions'
import { testPasswordStrength } from '../Form/FormFunctions'

const successMsg = 'Your password has been changed! You can now log in.';
var errorMsg = ''

class ChangeForgotPassword extends Component {
    constructor(props) {
        super(props)
        this.state = {
            success: false,
            error: false,
            newPassword: '',
            conNewPassword: '',
            strongPassword: false,
        }
        this.onSubmit = this.onSubmit.bind(this)
        this.onChange = this.onChange.bind(this)
    }

    componentDidMount() {
        if (localStorage.getItem("userName") !== null) {
            localStorage.clear()
            window.location.reload(true)
        }
    }

    async onSubmit(e) {
        e.preventDefault();
        this.setState({
            strongPassword: await testPasswordStrength(this.state.newPassword, this.state.conNewPassword, "New password"),
        });
        if (!this.state.strongPassword) {
            return
        }
        changeForgotPassword(this.props.match.params.token, this.state.newPassword).then(res => {
            if (res.status === 200) {
                this.setState({success: true, error: false})
            }
            else {
                errorMsg = res.data.message;
                this.setState({error: true})
            }
        })
    }

    onChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    render() {
        return (
            <div>
                <MDBContainer>
                    <form className="mx-auto custom-form" onSubmit={this.onSubmit}>
                        <h1> Change Password </h1>
                        <MDBInput type="password" name="newPassword" label="New Password" value={this.state.newPassword} onChange={this.onChange} required />
                        <small className="form-text text-muted password-description">
                            Your password must be 6-20 characters long, and it should consist of numbers, at least 1 capital letter, and a special character
                        </small>
                        <MDBInput type="password" name="conNewPassword" label="Confirm New Password" value={this.state.conNewPassword} onChange={this.onChange} required />
                        <MDBBtn type="submit" color="primary"> Change Password </MDBBtn>
                        <div>
                            <span style={{ color: 'red' }}>
                                {this.state.error ? errorMsg : ''}
                            </span>
                            <span style={{ color: 'green' }}>
                                {this.state.success ? successMsg : ''}
                            </span>
                        </div>
                    </form>
                </MDBContainer>
            </div>
        );
    }
}

export default ChangeForgotPassword