import React, { Component } from 'react'

class Landing extends Component {
    goToLogin() {
        this.props.history.push("/login")
    }

    render() {
        const loginRegLink = (
            <div className="container">
                <h1 className="h1-upward">Upward Language Company</h1>
                <div className="row">
                    <div className="col-sm">
                        <h4>Raise your English and Life to new heights.
                        <br />For free.
                        <br />No joke. No tricks.</h4>
                        <br />
                        <p>
                            Learn alongside English learners from FIVE continents and compete by being the BEST at our quizzes.
                        </p>
                    </div>
                    <div className="col-sm">
                        <div className="card">
                            <div className="card-body">
                                <button className="btn btn-primary btn-lg" onClick={this.goToLogin.bind(this)}>Login</button>
                                <small><p>Get back into it!</p></small>
                                <p className="remove-space"><span style={{fontSize: "24px"}}><a href="/register">CREATE NEW ACCOUNT</a></span>
                                <br />
                                <small>We value your privacy and would never sell your information to other companies.</small>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <br></br><br></br>
                <h3><a href="./mobile-app.apk" download>Try the Android App!</a></h3>
            </div >
        )

        const userLink = (
            <div className="container">
                <br />
                <div className="card">
                    <div className="card-body">
                        <h2 style={{ textDecoration: "underline" }}>Want better English + a better life?</h2>
                        <br />
                        <h3><a href="/articles">Learn</a></h3>
                        <p className="text-left">Check out our latest articles to improve your English slangs, cultural references, vocabulary, and listening &amp; pronunciation. These are uploaded weekly so be sure to check back for more learning opportunities.</p>
                        <h3><a href="/quiz">Quiz</a></h3>
                        <p className="text-left">After reading an article, head over to the Quiz page! Test your comprehension with timed quiz questions. The faster you answer correctly the better your score! Do well and you have a chance of being featured on the public <a href="/rankings">Rankings</a> page.</p>
                        <h3><a href="/rankings">Rankings</a></h3>
                        <p className="text-left">Keep up with the community rankings. See how you compare! Speed and accuracy are the keys to getting the best score.</p>
                    </div>
                </div>
            </div>
        )

        return (
            <div>
                {localStorage.getItem("usertoken") ? userLink : loginRegLink}
            </div>
        )
    }
}

export default Landing