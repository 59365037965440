import React, { Component } from 'react'
import './App.css'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Navbar from './components/Navbar'
import Landing from './components/Landing'
import Login from './components/Form/Login'
import Register from './components/Form/Register'
import ViewUsers from './components/Admin/User/ViewUsers'
import Profile from './components/User/Profile/Profile'
import { getAccountDetails } from './components/UserFunctions'
import Account from './components/User/Account'
import Password from './components/User/Password'
import UploadArticle from './components/Admin/UploadArticle'
import ViewArticles from './components/Articles/ViewArticles'
import Article from './components/Articles/Article'
import ViewQuizList from './components/Admin/Quizzes/ViewQuizList'
import ModifyQuiz from './components/Admin/Quizzes/ModifyQuiz'
import Quiz from './components/Quiz/Quiz'
import Confirm from './components/User/Confirm'
import Rankings from './components/Rankings/Rankings'
import RegistrationComplete from './components/User/RegistrationComplete'
import ChangeForgotPassword from './components/User/ChangeForgotPassword'
import ForgotPassword from './components/User/ForgotPassword'
import ForgotUsername from './components/User/ForgotUsername'


export default class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAdmin: false,
    }
  }

  checkIfAdmin = () => {
    if (localStorage.getItem("userName") !== null) {
      getAccountDetails(localStorage.getItem("userName")).then(res => {
        if (res.status === 200) {
          if (res.data.isAdmin === 1) {
            this.setState({ isAdmin: res.data.isAdmin })
            localStorage.setItem('isAdmin', true)
          }
          else {

            localStorage.removeItem('isAdmin')
          }
        }
        else {
          this.setState({ isAdmin: false })
          localStorage.removeItem('isAdmin')
        }
      })
    }
    else {
      this.setState({ isAdmin: false })
      localStorage.removeItem('isAdmin')
    }
  }

  logoutCallback = () => {
    this.setState({ isAdmin: false })
  }

  componentDidMount() {
    if (localStorage.getItem("usertoken") !== null) {
      this.checkIfAdmin()
    }
  }
  
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isAdmin !== this.props.isAdmin) {
      this.setState({ isAdmin: true })
    }
  }

  render() {
    return (
      <Router>
        <div className="App">
          <Navbar isAdmin={this.state.isAdmin} callBackFromParent={this.logoutCallback} />
          <div className="container">
            <Switch>
              <Route exact path="/" component={Landing} />
              <Route exact path="/register" component={Register} />
              <Route exact path="/login" render={(props) => <Login callBackFromParent={this.checkIfAdmin}{...props} />} />
              <Route exact path="/profile/:userName" component={Profile} />
              <Route exact path="/account" component={Account} />
              <Route exact path="/users" render={(props) => <ViewUsers isAdmin={this.state.isAdmin} callBackFromParent={this.checkIfAdmin}{...props} />} />
              <Route exact path="/password" component={Password} />
              <Route exact path="/upload" component={UploadArticle} />
              <Route exact path="/articles/:title?" component={ViewArticles} />
              <Route path="/article/:title" component={Article} />
              <Route exact path="/quizzes" component={ViewQuizList} />
              <Route exact path="/admin/:title/quiz" component={ModifyQuiz} />
              <Route exact path="/quiz" component={Quiz} />
              <Route path="/confirm/:token" component={Confirm} />
              <Route exact path="/rankings" component={Rankings} />
              <Route exact path="/complete" component={RegistrationComplete} />
              <Route exact path="/forgotpassword" component={ForgotPassword} />
              <Route exact path="/changepassword/:token" component={ChangeForgotPassword} />
              <Route exact path="/forgotusername" component={ForgotUsername} />
            </Switch>
          </div>
        </div>
      </Router>
    )
  }
}