import React, { Component } from 'react'
import { uploadArticle } from '../Articles/ArticleFunctions'
import isUrl from 'is-url'

let uploadError = false
let uploadErrorMessage = ''

class UploadArticle extends Component {
    constructor(props) {
        super(props)
        this.state = {
            articleTitle: '',
            link: '',
        }

        this.onChange = this.onChange.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
    }

    componentDidMount() {
        uploadError = false
        if (localStorage.getItem("isAdmin")) {
            this.props.history.push("/upload")
        }
        else {
            this.props.history.push("/")
        }
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value })
    }

    async onSubmit(e) {
        e.preventDefault();

        if (!isUrl(this.state.link)) {
            uploadError = true
            uploadErrorMessage = "Please provide a real link."
            this.props.history.push('/upload')
            return
        }

        let article = {
            title: this.state.articleTitle,
            link: this.state.link
        }

        uploadArticle(article).then(response => {
            if (response.status === 200) {
                uploadError = false
                this.props.history.push('/quizzes')
            }
            else {
                uploadError = true;
                uploadErrorMessage = response.data.message;
                this.props.history.push('/upload')
            }
        })
        return false
    }

    render() {
        return (
            <div>
                <h1 className="h1-upward">Upload New Article</h1>
                <br />
                <form onSubmit={this.onSubmit}>
                    <div className="form-group row">
                        <h5 className="col-form-label">Title</h5>
                        <div className="col-sm-10">
                            <input type="text" maxLength="255" className="form-control" name="articleTitle" placeholder="Title" onChange={this.onChange} required />
                        </div>
                    </div>
                    <div className="form-group row">
                        <h5 className="col-form-label">Link</h5>
                        <div className="col-sm-10">
                            <input type="text" maxLength="255" className="form-control" name="link" placeholder="Link" onChange={this.onChange} required />
                        </div>
                    </div>
                    <button type="submit" className="btn btn-primary mb-2">Upload</button>
                    <br />
                </form>

                <span style={{ color: 'red' }}>
                    {uploadError ? uploadErrorMessage : ''}
                </span>
            </div>
        )
    }
}

export default UploadArticle