import axios from 'axios'
import getURL from '../configuration'
import { POSITIVE_RESPONSES, NEGATIVE_RESPONSES, HIGHSCORE_RESPONSES, LOWSCORE_RESPONSES } from './QuizFeedBackText'
const SERVER = getURL()

const customHeader = () => { return { headers: { 'Authorization': localStorage.getItem('usertoken') } } }

export const QUESTION_TYPE_TRUE_FALSE = 0
export const QUESTION_TYPE_MULTIPLE_CHOICE = 1
export const QUESTION_TYPE_FILL_IN = 2
export const MULTIPLE_CHOICE_OPTION_SIZE = 4
export const DIFFICULTY_MODIFIERS_NAMES = ['Normal', 'Hard']

export const getQuiz = (title) => {
    return axios
        .get(SERVER + '/article/' + encodeURIComponent(title) + '/quiz', customHeader())
        .then(response => {
            return response
        })
        .catch(err => {
            return err.response
        })
}

export const getAllQuizzes = () => {
    return axios
        .get(SERVER + '/quizzes', customHeader())
        .then(response => {
            return response
        })
        .catch(err => {
            return err.response
        })
}

export const addQuestion = (title, quiz) => {
    return axios
        .post(SERVER + '/article/' + encodeURIComponent(title) + '/question', quiz, customHeader())
        .then(response => {
            return response
        })
        .catch(err => {
            return err.response
        })
}

export const deleteQuestion = (title, question) => {
    return axios
        .delete(SERVER + "/article/" + encodeURIComponent(title) + "/question/" + encodeURIComponent(question), customHeader())
        .then(response => {
            return response
        })
        .catch(err => {
            return err.response
        })
}

export const updateQuestion = (title, oldQuestion, question) => {
    return axios
        .put(SERVER + "/article/" + encodeURIComponent(title) + "/question/" + encodeURIComponent(oldQuestion), question, customHeader())
        .then(response => {
            return response
        })
        .catch(err => {
            return err.response
        })
}

export const submitQuizResult = (quizResult) => {
    return axios
        .post(SERVER + '/score', quizResult, customHeader())
        .then(response => {
            return response
        })
        .catch(err => {
            return err.response
        })
}

export const getRandomPositiveResponse = () => {
    return POSITIVE_RESPONSES[Math.floor(Math.random() * POSITIVE_RESPONSES.length)];
}

export const getRandomNegativeResponse = () => {
    return NEGATIVE_RESPONSES[Math.floor(Math.random() * NEGATIVE_RESPONSES.length)];
}

export const getRandomHighScoreResponse = () => {
    return HIGHSCORE_RESPONSES[Math.floor(Math.random() * HIGHSCORE_RESPONSES.length)];
}

export const getRandomLowScoreResponse = () => {
    return LOWSCORE_RESPONSES[Math.floor(Math.random() * LOWSCORE_RESPONSES.length)];
}