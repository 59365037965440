import React, { Component } from 'react'
import { updateAccountHelper, getAllUsers, getAccountDetails, updateAccount, deleteUser, unflag } from '../../UserFunctions'
import { MDBDataTable, MDBContainer, MDBBtn, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBInput, MDBIcon } from 'mdbreact';
import ReactExport from "react-export-excel";
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import Switch from "react-switch";
import Modal from 'react-modal';
import { testValidEmail } from '../../Form/FormFunctions'
import PhoneInput from 'react-phone-number-input'

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

let customStylesDelete = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
    overlay: {
        zIndex: 100
    },
}

const customStylesEdit = JSON.parse(JSON.stringify(customStylesDelete))
customStylesEdit.content.height = '92vh'
customStylesEdit.content.overflow = 'scroll'

const columns = [
    {
        label: 'ID',
        field: 'id',
        sort: 'asc',
        width: 50
    },
    {
        label: 'Username',
        field: 'user_name',
        sort: 'asc',
        width: 200
    },
    {
        label: 'Phone',
        field: 'phone',
        sort: 'asc',
        width: 220
    },
    {
        label: 'Email',
        field: 'email',
        sort: 'asc',
        width: 350
    },
    {
        label: 'First Name',
        field: 'first_name',
        sort: 'asc',
        width: 150
    },
    {
        label: 'Last Name',
        field: 'last_name',
        sort: 'asc',
        width: 150
    },
    {
        label: 'Country',
        field: 'country',
        sort: 'asc',
        width: 150
    },
    {
        label: 'Region',
        field: 'state',
        sort: 'asc',
        width: 150
    },
    {
        label: 'Hobbies',
        field: 'hobbies',
        sort: 'asc',
        width: 200
    },
    {
        label: "Flagged",
        field: 'flagged',
        sort: 'asc',
    },
    {
        label: "Confirmed Account",
        field: 'confirmed',
        sort: 'asc',
    },
    {
        label: "Role",
        field: 'is_admin',
        sort: 'asc',
        width: 150
    },
];

let rows = []

let data = {
    columns,
    rows
}

let updateError = false
let updateErrorMessage = ''

class ViewUsers extends Component {
    constructor(props) {
        super(props)
        this.state = {
            users: [],
            data: {
                columns,
                rows
            },
            infoModal: false,
            confirmDeleteModal: false,
            flagModal: false,
            confirmEmail: false,
            updateUser: {
                userName: '',
                phone: '',
                email: '',
                firstName: '',
                lastName: '',
                country: '',
                state: '',
                hobbies: '',
                isAdmin: false,
                confirmed: false,
                flagged: false
            },
            flaggedBy: '',
            validEmail: false,
            alreadyConfirmed: false
        }

        this.makeAdmin = this.makeAdmin.bind(this)
        this.onChange = this.onChange.bind(this)
    }

    toggleInfoModal(e) {
        this.setState({
            infoModal: !this.state.infoModal
        });
    }

    toggleConfirmDeleteModal(e) {
        this.setState({
            confirmDeleteModal: !this.state.confirmDeleteModal
        });
    }

    displayFlaggers() {
        let strArr = this.state.flaggedBy.split(',')
        for (var s = 0; s < strArr.length; s++) {
            var el = document.createElement("p")
            el.textContent = strArr[s]
            document.getElementById("flaggers").appendChild(el)
        }
    }

    async toggleFlagModal(e) {
        var me = this
        function toggleModal() {
            me.setState({
                flagModal: !me.state.flagModal
            })
        }

        await toggleModal()
        if (this.state.flagModal) {
            this.displayFlaggers()
        }
    }

    handleClick = (e, data) => {
        if (e.target.id === "flag") {
            getAccountDetails(data).then(res => {
                this.setState({
                    updateUser: res.data,
                    flaggedBy: res.data.flaggedBy,
                    alreadyConfirmed: res.data.confirmed
                }, () => this.toggleFlagModal())
            })
        }
        else {
            getAccountDetails(data).then(res => {
                this.setState({
                    updateUser: res.data,
                    alreadyConfirmed: res.data.confirmed
                })
            })
        }

    }

    componentDidMount() {
        updateError = false
        if (localStorage.getItem("isAdmin")) {
            this.props.history.push("/users")
        }
        else {
            this.props.history.push("/")
        }
    }

    componentWillMount() {
        if (localStorage.getItem("isAdmin")) {
            getAllUsers(localStorage.getItem("usertoken")).then(res => {
                if (res.data === undefined) {
                    alert("There are no users!")
                }
                else {
                    let tempCopy = []
                    Object.assign(tempCopy, res.data)
                    for (var i = 0; i < tempCopy.length; i++) {
                        delete tempCopy[i]["password"]
                        if (tempCopy[i].flagged) {
                            tempCopy[i].flagged = (<MDBIcon className="red-text clickable-icon" icon="flag" value={tempCopy[i].flagged} id="flag" />)
                        }
                        else {
                            tempCopy[i].flagged = "No flags"
                        }
                        if (tempCopy[i].confirmed) {
                            tempCopy[i].confirmed = "Confirmed"
                        }
                        else {
                            tempCopy[i].confirmed = "Email Sent"
                        }
                        if (tempCopy[i].is_admin === 1) {
                            tempCopy[i].is_admin = "Admin"
                        }
                        else {
                            tempCopy[i].is_admin = "Basic"
                        }
                        tempCopy[i]["edit"] = (<MDBBtn color="primary" size="sm" onClick={this.toggleInfoModal.bind(this)}>Edit</MDBBtn>)
                        if (tempCopy[i].user_name !== "admin") {
                            tempCopy[i]["delete"] = (<MDBBtn color="danger" size="sm" onClick={this.toggleConfirmDeleteModal.bind(this)}>Delete</MDBBtn>)
                        }
                        let tempUserName = tempCopy[i].user_name
                        tempCopy[i]["clickEvent"] = e => this.handleClick(e, tempUserName)
                    }
                    this.setState({ users: tempCopy })
                    Object.assign(data.rows, this.state.users)
                    this.setState({ data: data })
                }
            })
        }
        else {
            this.props.history.push("/")
        }
    }

    componentDidUpdate(prevProps) {
        data.rows = rows
        if (prevProps.users !== this.props.users) {
            this.setState({ users: this.props.users })
        }
    }

    makeAdmin(checked) {
        if (checked) {
            this.setState({ updateUser: { ...this.state.updateUser, isAdmin: true } })
        }
        else {
            this.setState({ updateUser: { ...this.state.updateUser, isAdmin: false } })
        }
    }

    onChange(e) {
        this.setState({ updateUser: { ...this.state.updateUser, [e.target.name]: e.target.value } })
    }

    selectCountry(val) {
        this.setState({ updateUser: { ...this.state.updateUser, country: val } });
    }

    selectRegion(val) {
        this.setState({ updateUser: { ...this.state.updateUser, state: val } });
    }

    phoneInputHandler = (values) => {
        this.setState({ updateUser: { ...this.state.updateUser, phone: values } });
    }

    deleteUserAccount(e, user_name) {
        e.preventDefault()

        deleteUser(user_name, localStorage.getItem('usertoken')).then(res => {
            if (res.status === 200) {
                updateError = false;
                alert("Sucessfully updated user's account!");
                window.location.reload();
            }
            else {
                updateError = true;
                updateErrorMessage = res.data.message;
            }
        })
    }

    async UpdateUserAccount(e, user) {
        e.preventDefault()

        this.setState({
            validEmail: await testValidEmail(user.email)
        });

        if (!this.state.validEmail) {
            return
        }

        updateAccount(user).then(res => {
            let response = updateAccountHelper(res, '/users')
            updateError = response.error;
            updateErrorMessage = response.message;
        })
    }

    removeFlag(e, user) {
        unflag(user.userName).then(res => {
            let response = updateAccountHelper(res, '/users')
            updateError = response.error;
            updateErrorMessage = response.message;
        })
    }

    confirmEmail(checked) {
        if (checked) {
            this.setState({ updateUser: { ...this.state.updateUser, confirmed: true } })
        }
        else {
            this.setState({ updateUser: { ...this.state.updateUser, confirmed: false } })
        }
    }

    render() {
        const reviewFlagModal = (
            <div>
                <Modal style={customStylesEdit} ariaHideApp={false} isOpen={this.state.flagModal} toggleFlagModal={this.toggleFlagModal.bind(this)}>
                    <MDBModalHeader>
                        Review Flag
                    </MDBModalHeader>
                    <MDBModalBody>
                        <h2>{this.state.updateUser.userName}</h2>
                        <p>This user has been flagged for inappropriate content by these users:</p>
                        <p id="flaggers"></p>
                        <p>Would you like to remove this flag?</p>
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn color="danger" onClick={this.toggleFlagModal.bind(this)}>Cancel</MDBBtn>
                        <MDBBtn color="primary"
                            onClick={e => this.removeFlag(e, this.state.updateUser)}>Confirm</MDBBtn>
                    </MDBModalFooter>
                </Modal>
            </div>
        )

        const displayUserInfo = (
            <div className="container">
                <MDBContainer>
                    <Modal style={customStylesEdit} ariaHideApp={false} isOpen={this.state.infoModal} toggleInfoModal={this.toggleInfoModal.bind(this)}>
                        <MDBModalHeader>Update User Account</MDBModalHeader>
                        <MDBModalBody>
                            <h5>Username:</h5> {this.state.updateUser.userName}<br /><br />

                            <h5>Phone:</h5>
                            <PhoneInput
                                maxLength="30"
                                onChange={this.phoneInputHandler}
                                value={this.state.updateUser.phone}
                                placeholder={this.state.updateUser.phone} /><br />

                            <h5>Email:</h5>
                            <MDBInput
                                name="email"
                                hint={this.state.updateUser.email}
                                onChange={this.onChange}
                                maxLength="30" /><br />

                            <h5>First Name:</h5>
                            <MDBInput
                                name="firstName"
                                hint={this.state.updateUser.firstName}
                                onChange={this.onChange}
                                maxLength="30" /><br />

                            <h5>Last Name:</h5>
                            <MDBInput
                                name="lastName"
                                hint={this.state.updateUser.lastName}
                                onChange={this.onChange}
                                maxLength="30" /><br />

                            <h5>Country:</h5>
                            <CountryDropdown
                                valueType="short"
                                value={this.state.updateUser.country}
                                onChange={(val) => this.selectCountry(val)} /><br /><br />

                            <h5>Region:</h5>
                            <RegionDropdown
                                countryValueType="short"
                                country={this.state.updateUser.country}
                                value={this.state.updateUser.state}
                                onChange={(val) => this.selectRegion(val)} /><br /><br />

                            <h5>Hobbies:</h5>
                            <MDBInput
                                name="hobbies"
                                hint={this.state.updateUser.hobbies}
                                onChange={this.onChange}
                                maxLength="300" /><br />

                            <h5>Confirm Account:</h5>
                            <Switch onChange={this.confirmEmail.bind(this)} checked={this.state.updateUser.confirmed} disabled={this.state.alreadyConfirmed} />

                            <h5>Admin:</h5>
                            <Switch onChange={this.makeAdmin} checked={!!this.state.updateUser.isAdmin} disabled={(this.state.updateUser.userName === "admin") || (localStorage.getItem("userName") === this.state.updateUser.userName)} />
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn color="danger" onClick={this.toggleInfoModal.bind(this)}>Cancel</MDBBtn>
                            <MDBBtn color="primary"
                                onClick={e => this.UpdateUserAccount(e, this.state.updateUser)}>Save changes</MDBBtn>
                        </MDBModalFooter>
                    </Modal>
                </MDBContainer>
            </div>
        )

        const confirmDelete = (
            <div className="container">
                <Modal ariaHideApp={false} isOpen={this.state.confirmDeleteModal} style={customStylesDelete}>
                    <MDBModalHeader>Are you sure?</MDBModalHeader>
                    Delete user: {this.state.updateUser.userName}<br /><br />
                    <MDBBtn color="danger" onClick={this.toggleConfirmDeleteModal.bind(this)}>Cancel</MDBBtn>
                    <MDBBtn color="primary" onClick={e => this.deleteUserAccount(e, this.state.updateUser.userName)} disabled={localStorage.getItem("userName") === this.state.updateUser.userName}>Confirm</MDBBtn>
                </Modal>
            </div>
        )

        return (
            <div className="container">
                <h1 className="h1-upward">Users</h1>
                <MDBBtn onClick={() => { this.props.history.push("/register") }} color="primary" size="me">Create New User</MDBBtn>

                <ExcelFile filename="UpwardLanguage_Users" element={<MDBBtn color="green" size="me">Export to EXCEL</MDBBtn>}>
                    <ExcelSheet data={this.state.users} name="Users">
                        <ExcelColumn label="ID" value="id" />
                        <ExcelColumn label="Username" value="user_name" />
                        <ExcelColumn label="Email" value="email" />
                        <ExcelColumn label="Phone" value="phone" />
                        <ExcelColumn label="FirstName" value="first_name" />
                        <ExcelColumn label="LastName" value="last_name" />
                        <ExcelColumn label="Country" value="country" />
                        <ExcelColumn label="Region" value="state" />
                        <ExcelColumn label="Hobbies" value="hobbies" />
                        <ExcelColumn label="Flagged" value="flagged" />
                        <ExcelColumn label="Confirmed Account" value="confirmed" />
                        <ExcelColumn label="Role" value="is_admin" />
                    </ExcelSheet>
                </ExcelFile>

                {displayUserInfo}
                {confirmDelete}
                {reviewFlagModal}

                <MDBDataTable
                    maxHeight="75vh"
                    striped
                    bordered
                    responsive
                    data={this.state.data}
                />

                <span style={{ color: 'red' }}>
                    {updateError ? updateErrorMessage : ''}
                </span>
            </div>
        )
    }
}

export default ViewUsers