import React, { Component } from 'react'
import { MDBContainer, MDBInput, MDBBtn } from 'mdbreact'
import '../Form/Form.css'
import { changePassword } from '../UserFunctions'
import { testPasswordStrength } from '../Form/FormFunctions'

var changePasswordError = false
let changePasswordErrorMessage = ''

class Password extends Component {

    constructor() {
        super()
        this.state = {
            oldPassword: '',
            newPassword: '',
            conNewPassword: '',
            strongPassword: false,
        };

        this.onSubmit = this.onSubmit.bind(this)
        this.onChange = this.onChange.bind(this)
    }

    async onSubmit(e) {
        e.preventDefault();

        this.setState({
            strongPassword: await testPasswordStrength(this.state.newPassword, this.state.conNewPassword, "New password"),
        });

        if (!this.state.strongPassword) {
            return
        }

        const updatedPassword = {
            userName: localStorage.getItem('userName'),
            oldPassword: this.state.oldPassword,
            newPassword: this.state.newPassword,
        }

        changePassword(updatedPassword).then(res => {
            if (res.status === 200) {
                changePasswordError = false;
                alert("Sucessfully updated password!");
                this.props.history.push('/profile')
                return;
            }
            else {
                changePasswordError = true;
                changePasswordErrorMessage = res.data.message;
                this.props.history.push('/password')
            }
        })
    }

    onChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    render() {

        const ChangePasswordErrorRender = (
            <div>
                {changePasswordErrorMessage}
            </div>
        )

        return (
            <MDBContainer>
                <form className="mx-auto custom-form" onSubmit={this.onSubmit}>
                    <h1 className="h1-upward"> Change Password </h1>
                    <MDBInput type="password" name="oldPassword" label="Old Password" value={this.state.oldPassword} onChange={this.onChange} required />
                    <MDBInput type="password" name="newPassword" label="New Password" value={this.state.newPassword} onChange={this.onChange} required />
                    <small className="form-text text-muted password-description">
                        Your password must be 6-20 characters long, and it should consist of numbers, at least 1 capital letter, and a special character
                    </small>
                    <MDBInput type="password" name="conNewPassword" label="Confirm New Password" value={this.state.conNewPassword} onChange={this.onChange} required />
                    <MDBBtn type="submit" color="primary"> Change Password </MDBBtn>
                    <div>
                        <span style={{ color: 'red' }}>
                            {changePasswordError ? ChangePasswordErrorRender : ''}
                        </span>
                    </div>
                </form>
            </MDBContainer>
        );
    }
}

export default Password