import React, { Component } from 'react';
import { MDBContainer, MDBInput, MDBBtn } from 'mdbreact'
import './Form.css';
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { register } from '../UserFunctions'
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import { testPasswordStrength, testValidEmail } from './FormFunctions'

var registerError = false;
var registerErrorMessage = ''

class Register extends Component {
    constructor() {
        super()
        this.state = {
            userName: '',
            phone: '',
            email: '',
            firstName: '',
            lastName: '',
            password: '',
            conPassword: '',
            country: '',
            state: '',
            hobbies: '',
            strongPassword: false,
            validEmail: false,
        };
        this.onChange = this.onChange.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
    }

    async onSubmit(e) {
        e.preventDefault();

        this.setState({
            strongPassword: await testPasswordStrength(this.state.password, this.state.conPassword, "Password"),
            validEmail: await testValidEmail(this.state.email)
        });


        if (!this.state.strongPassword) {
            return
        }
        if (!this.state.validEmail) {
            return
        }

        const newUser = {
            userName: this.state.userName,
            phone: this.state.phone,
            email: this.state.email,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            password: this.state.password,
            country: this.state.country,
            state: this.state.state,
            hobbies: this.state.hobbies
        }

        register(newUser).then(res => {
            if (res.status !== 200) {
                registerError = true
                registerErrorMessage = res.data.message
                this.props.history.push('/register')
            }
            else if(localStorage.getItem('isAdmin')==='true'){
                this.props.history.push('/users')
            }
            else {
                registerError = false
                this.props.history.push('/complete')
            }
        })
    }

    phoneInputHandler = (values) => {
        this.setState({
            phone: values,
        });
    }

    selectCountry(val) {
        this.setState({ country: val });
    }

    selectRegion(val) {
        this.setState({ state: val });

    }

    onChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    componentDidMount() {
        registerError = false
        if (localStorage.getItem("usertoken") === null || localStorage.getItem("isAdmin")) {
            this.props.history.push('/register')
        }
        else {
            this.props.history.push('/')
        }
    }

    render() {
        const RegisterErrorRender = (
            <div>
                Could not register new user!<br />
                {registerErrorMessage}
            </div>
        )

        const { country, state } = this.state;

        return (
            <MDBContainer>
                <form className="text-center border p-5 mx-auto custom-form" onSubmit={this.onSubmit}>
                    <h1 className="h1-upward"> Let's Join </h1>
                    <div>
                        <span style={{ color: 'red' }}>
                            {registerError ? RegisterErrorRender : ''}
                        </span>
                    </div>
                    <MDBInput name="userName" maxLength="30" label="Username" value={this.state.userName} onChange={this.onChange} required />
                    <PhoneInput maxLength="30" onChange={this.phoneInputHandler} value={this.state.phone} />
                    <MDBInput type="email" name="email" maxLength="30" label="Email" value={this.state.email} onChange={this.onChange} required />
                    <MDBInput name="firstName" maxLength="30" label="First Name" value={this.state.firstName} onChange={this.onChange} required />
                    <MDBInput name="lastName" maxLength="30" label="Last Name" value={this.state.lastName} onChange={this.onChange} required />
                    <MDBInput type="password" name="password" maxLength="255" label="Password" value={this.state.password} onChange={this.onChange} required />
                    <small className="form-text text-muted password-description">
                        Your password must be 6-20 characters long, and it should consist of numbers, at least 1 capital letter, and a special character
                    </small>
                    <MDBInput type="password" name="conPassword" maxLength="255" label="Confirm Password" value={this.state.conPassword} onChange={this.onChange} required />
                    <CountryDropdown valueType="short" className="browser-default custom-select mb-4" value={country} onChange={(val) => this.selectCountry(val)} required />
                    <RegionDropdown countryValueType="short" className="browser-default custom-select mb-4" placeholder="Select Province/State" country={country} value={state} onChange={(val) => this.selectRegion(val)} required />
                    <MDBInput type="textarea" name="hobbies" label="Hobbies" value={this.state.hobbies} onChange={this.onChange} />
                    <MDBBtn type="submit" color="primary"> Sign Up </MDBBtn>
                </form>
            </MDBContainer>
        )
    };
}

export default Register;