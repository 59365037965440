import axios from 'axios'
import getURL from '../configuration'
const SERVER = getURL()

const customHeader = () => { return { headers: { 'Authorization': localStorage.getItem('usertoken') } } }

export const uploadArticle = (articleContent) => {
    return axios
        .post(SERVER + '/upload', articleContent, customHeader())
        .then(response => {
            return response
        })
        .catch(err => {
            return err.response
        })
}

export const getAllArticles = () => {
    return axios
        .get(SERVER + '/articles', customHeader())
        .then(response => {
            return response
        })
        .catch(err => {
            return err.response
        })
}

export const getArticle = (title) => {
    return axios
        .get(SERVER + '/article/' + encodeURIComponent(title), customHeader())
        .then(response => {
            return response
        })
        .catch(err => {
            return err.response
        })
}

export const deleteArticle = (title) => {
    return axios
        .delete(SERVER + '/article/' + encodeURIComponent(title), customHeader())
        .then(response => {
            return response
        })
        .catch(err => {
            return err.response
        })
}