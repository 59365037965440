import React, { Component } from 'react'
import { MULTIPLE_CHOICE_OPTION_SIZE } from '../../../Quiz/QuizFunctions'

class MultipleChoiceOptions extends Component {
    constructor(props) {
        super(props)
        this.state = {
            options: []
        }
    }

    componentDidMount() {
        if (this.props.options !== undefined) {
            this.setState({ options: this.props.options })
            for (var i = 0; i < this.props.options.length; i++) {
                document.getElementById("is_answer_" + i).checked = this.props.options[i].is_answer
                document.getElementById("option_" + i).value = this.props.options[i].option
            }
        }
        if (this.props.back === true) {
            this.setState({
                options: []
            })
            this.props.backToggle()
        }
    }

    async updateOption(e) {
        let options = this.state.options
        var me = this

        function internalUpdating() {
            if (options[e.target.name] !== undefined) {
                options[e.target.name] = { option: e.target.value, is_answer: options[e.target.name].is_answer }
            }
            else {
                options[e.target.name] = { option: e.target.value, is_answer: false }
            }

            me.setState({
                options: options
            })
        }

        await internalUpdating()
        this.optionsFilled()
    }

    updateCorrectAnswer(e) {
        let options = this.state.options

        if (options[e.target.value] !== undefined) {
            options[e.target.value] = { option: options[e.target.value].option, is_answer: true }
        }
        else {
            options[e.target.value] = { option: '', is_answer: true }
        }

        for (var j = 0; j < options.length; j++) {
            if (j !== parseInt(e.target.value)) {
                if (options[j] !== undefined) {
                    options[j].is_answer = false
                }
            }
        }

        this.setState({
            options: options
        })

        this.answerSelectedCheck()
    }

    answerSelectedCheck = () => {
        this.props.answerSelectedCheck(true)
    }

    sendOptions = () => {
        this.props.getOptions(this.state.options)
    }

    optionsFilled = () => {
        for (var o = 0; o < MULTIPLE_CHOICE_OPTION_SIZE; o++) {
            if (this.state.options[o] === undefined || this.state.options[o].option === '' || this.state.options[o] === null || this.state.options[o] === 0) {
                this.props.optionsFilledCheck(false)
                break
            }
            else {
                this.props.optionsFilledCheck(true)
                this.sendOptions()
            }
        }
    }

    render() {
        return (
            <div>
                <input type="radio"
                    name="multipleChoice"
                    value="0"
                    onChange={this.updateCorrectAnswer.bind(this)}
                    id="is_answer_0" />
                <input type="text"
                    name="0"
                    size="30"
                    placeholder="Please enter optional answer..."
                    maxLength="200"
                    onChange={this.updateOption.bind(this)}
                    style={{ marginLeft: "2em" }}
                    id="option_0"></input><br /><br />
                <input type="radio"
                    name="multipleChoice"
                    value="1"
                    onChange={this.updateCorrectAnswer.bind(this)}
                    id="is_answer_1" />
                <input type="text"
                    name="1"
                    size="30"
                    placeholder="Please enter optional answer..."
                    maxLength="200"
                    onChange={this.updateOption.bind(this)}
                    style={{ marginLeft: "2em" }}
                    id="option_1"></input><br /><br />
                <input type="radio"
                    name="multipleChoice"
                    value="2"
                    onChange={this.updateCorrectAnswer.bind(this)}
                    id="is_answer_2" />
                <input type="text"
                    name="2"
                    size="30"
                    placeholder="Please enter optional answer..."
                    maxLength="200"
                    onChange={this.updateOption.bind(this)}
                    style={{ marginLeft: "2em" }}
                    id="option_2"></input><br /><br />
                <input type="radio"
                    name="multipleChoice"
                    value="3"
                    onChange={this.updateCorrectAnswer.bind(this)}
                    id="is_answer_3" />
                <input type="text"
                    name="3"
                    size="30"
                    placeholder="Please enter optional answer..."
                    maxLength="200"
                    onChange={this.updateOption.bind(this)}
                    style={{ marginLeft: "2em" }}
                    id="option_3"></input><br /><br />
                <small>Select which is the correct answer.</small>
            </div>
        )
    }
}

export default MultipleChoiceOptions