import React, { Component } from 'react'

class RegistrationComplete extends Component {
    render() {
        return (
            <div>
                <h1 style={{ marginTop: '4rem' }}>Registration submitted! Please check your email to confirm your account.</h1>
                <div style={{ textAlign: 'left' }} >
                    <br></br><p>You did it! You just signed up to take your English to the next level.</p>
                    <p>By the way, some email services (e.g. QQ, Gmail, Yahoo, Outlook) may send our emails to one of these mailboxes in your email account:</p>
                    <p>- Junk or Spam</p>
                    <p>- Promotions or Other</p>
                    <p>Check there if you don’t receive an email from us shortly.
                    <br /><br />
                        <span style={{
                            color: '#008fd4',
                            fontStyle: 'italic'
                        }}>You’re one step away from taking your English to the next level of greatness!</span></p>
                </div>
            </div>
        )
    }
}

export default RegistrationComplete