import axios from 'axios'
import getURL from './configuration'
const SERVER = getURL()

const customHeader = () => { return { headers: { 'Authorization': localStorage.getItem('usertoken') } } }
const customRoute = (userName) => { return SERVER + '/account/' + userName }

export const ROLES = ["Basic", "Admin"]

const checkExpirationErr = (err) => {
  if ((err.status === 400) && (err.data.message === 'jwt expired')) {
    alert('Your session has expired, please log in again.')
    localStorage.clear()
    window.location.reload(true)
  }
}

export const register = newUser => {
  return axios
    .post(SERVER + '/register', newUser)
    .then(response => {
      return response
    })
    .catch(err => {
      return err.response
    })
}

export const login = user => {
  return axios
    .post(SERVER + '/login', user)
    .then(response => {
      localStorage.setItem('usertoken', response.data.token)
      localStorage.setItem('userName', response.data.user_name)
      return response
    })
    .catch(err => {
      return err.response
    })
}

export const getAccountDetails = (userName) => {
  return axios
    .get(customRoute(userName), customHeader())
    .then(response => {
      return response
    })
    .catch(err => {
      checkExpirationErr(err.response)
      return err.response
    })
}

export const updateAccount = (updatedUser) => {
  return axios
    .put(customRoute(updatedUser.userName), updatedUser, customHeader())
    .then(response => {
      return response
    })
    .catch(err => {
      checkExpirationErr(err.response)
      return err.response
    })
}

export const updateAccountHelper = (res, currentPage) => {
  let error = false
  let message = ''
  if (res.status === 200) {
    error = false
    alert("Sucessfully updated user's account!")
    window.location.reload()
  }
  else if (res.status === 409) {
    error = true
    alert("Cannot update! Phone or Email already exists.")
    message = res.data.message
  }
  else {
    error = true
    message = res.data.message
    window.location.reload()
  }
  let returnObj = { error, message }
  return returnObj
}

export const getAllUsers = () => {
  return axios
    .get(SERVER + '/users', customHeader())
    .then(response => {
      return response
    })
    .catch(err => {
      checkExpirationErr(err.response)
      return err
    })
}

export const deleteUser = (userName) => {
  return axios
    .delete(customRoute(userName), customHeader())
    .then(response => {
      return response
    })
    .catch(err => {
      return err
    })
}

export const changePassword = (updatedPassword) => {
  return axios
    .put(customRoute(localStorage.getItem('userName')) + '/changePassword', updatedPassword, customHeader())
    .then(response => {
      return response
    })
    .catch(err => {
      return err.response
    })
}

export const flag = (userName) => {
  return axios
    .put(SERVER + '/flag/' + userName, {}, customHeader())
    .then(response => {
      return response
    })
    .catch(err => {
      return err.response
    })
}

export const unflag = (userName) => {
  return axios
    .put(SERVER + '/unflag/' + userName, {}, customHeader())
    .then(response => {
      return response
    })
    .catch(err => {
      return err.response
    })
}

export const confirmAccount = (token) => {
  return axios
    .put(SERVER + '/confirm/' + token, null, null)
    .then(response => {
      return response
    })
    .catch(err => {
      return err.response
    })
}

export const forgotPassword = (email) => {
  return axios
    .put(SERVER + '/forgotpassword', {
      email: email
    }, null)
    .then(response => {
      return response
    })
    .catch(err => {
      return err.response
    })
}

export const changeForgotPassword = (token, password) => {
  return axios
    .put(SERVER + '/changepassword/' + token, {
      password: password
    }, null)
    .then(response => {
      return response
    })
    .catch(err => {
      return err.response
    })
}

export const forgotUsername = (email) => {
  return axios
    .put(SERVER + '/forgotusername', {
      email: email
    }, null)
    .then(response => {
      return response
    })
    .catch(err => {
      return err.response
    })
}

export const getUserProfile = (userName) => {
  return axios
    .get(SERVER + '/profile/' + userName, customHeader())
    .then(response => {
      return response
    })
    .catch(err => {
      checkExpirationErr(err.response)
      return err.response
    })
}

export const flagUser = (userName) => {
  return axios
    .put(SERVER + '/flag/' + userName, userName, customHeader())
    .then(response => {
      return response
    })
    .catch(err => {
      checkExpirationErr(err.response)
      return err.response
    })
}