/*
PROFILE
This is used to display public information of a user.
*/
import React, { Component } from 'react'
import './Profile.css'
import { MDBContainer, MDBIcon, MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBBtn, MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem } from 'mdbreact'
import { getUserProfile, flagUser } from '../../UserFunctions'
import { getName } from 'country-list'

let profileError = false
let profileErrorMessage = ''
let flag = false
let flagSuccessMessage = "You successfully flagged this user!"

class Profile extends Component {
    constructor() {
        super()
        this.state = {
            userName: '',
            country: '',
            state: '',
            hobbies: '',
            score: 0,
            flag: false,
        }
    }

    componentDidMount() {
        if (localStorage.getItem('usertoken') === null) {
            this.props.history.push('/login')
        }
        else {
            getUserProfile(this.props.match.params.userName).then(res => {
                if (res.status === 200) {
                    this.setState({
                        userName: res.data.userName,
                        country: getName(res.data.country),
                        state: res.data.state,
                        hobbies: res.data.hobbies,
                        score: res.data.score
                    })
                }
                else {
                    profileError = true
                    profileErrorMessage = res.data.message
                    this.props.history.push('/profile/' + this.props.match.params.userName)
                }
            })
        }
    }

    toggle = () => {
        this.setState({
            flag: !this.state.flag
        });
    }

    flagUser = () => {
        flagUser(this.state.userName).then(res => {
            if (res.status === 200) {
                this.toggle()
                flag = true
                this.props.history.push('/profile/' + this.props.match.params.userName)
                setTimeout(() => {
                    flag = false;
                    this.props.history.push('/profile/' + this.props.match.params.userName)
                }, 3000);
            }
            else {
                this.toggle()
                profileError = true
                profileErrorMessage = res.data.message
                this.props.history.push('/profile/' + this.props.match.params.userName)
            }
        })
    }

    render() {
        const profileErrorRender = (
            <div>
                {profileErrorMessage}
            </div>
        )

        const flagMessageRender = (
            <div>
                {flagSuccessMessage}
            </div>
        )

        const userOptions = (
            <MDBDropdown className="extra">
                    <MDBDropdownToggle className="nav-link" tag="p" color="white" >
                        <MDBIcon icon="ellipsis-h" className="clickable-icon" size="sm" />
                    </MDBDropdownToggle>
                    <MDBDropdownMenu>
                        <MDBDropdownItem className="flag-item" onClick={this.toggle}>
                            <MDBIcon far icon="flag" className=" clickable-icon mr-3" size="sm" /> Flag User
                                </MDBDropdownItem>
                    </MDBDropdownMenu>
                </MDBDropdown>
        )

        return (
            <MDBContainer className="text-center border p-5 mx-auto customDisplay">
                <MDBModal isOpen={this.state.flag} toggle={this.toggle} centered>
                    <MDBModalHeader toggle={this.toggle}>Are you sure you want to flag this user?</MDBModalHeader>
                    <MDBModalBody>
                        You want to report this user because their profile contains inappropriate content
                        <small className="form-text text-muted"><i>This action CANNOT be undone!</i></small>
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn color="primary" onClick={this.flagUser}>Confirm</MDBBtn>
                        <MDBBtn color="danger" onClick={this.toggle}>Cancel</MDBBtn>
                    </MDBModalFooter>
                </MDBModal>
                <div className="text-center">
                    <span>
                        <h3 id="username"><b>{this.state.userName}</b></h3>
                        {localStorage.getItem("userName") === this.state.userName ? '' : userOptions}
                    </span>
                </div>
                <div>
                    <br />
                    <small className="form-text text-muted user-location">
                        <i>From {this.state.state}</i>
                    </small>
                </div>
                <div>
                    Hobbies:
                    <p id="hobbies">{this.state.hobbies}</p>
                </div>
                <div id="score"><h5><b>Total score: {this.state.score} </b></h5></div>
                <div>
                    <span style={{ color: 'red' }}>
                        {profileError ? profileErrorRender : ''}
                    </span>
                </div>
                <div>
                    <span style={{ color: 'green' }}>
                        {flag ? flagMessageRender : ''}
                    </span>
                </div>
            </MDBContainer>
        )
    }
}

export default Profile