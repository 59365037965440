import React, { Component } from 'react'
import { DIFFICULTY_MODIFIERS_NAMES } from '../../../Quiz/QuizFunctions'

class DifficultyOption extends Component {
    constructor(props) {
        super(props)
        this.state = {
            difficulty: 0
        }
    }

    componentDidMount() {
        if (this.props.difficulty !== undefined) {
            this.setState({ difficulty: this.props.difficulty })
            for (var i = 0; i < DIFFICULTY_MODIFIERS_NAMES.length; i++) {
                let option_string = DIFFICULTY_MODIFIERS_NAMES[i]
                let option = document.createElement('option')
                option.textContent = option_string
                option.value = i
                if (i === parseInt(this.props.difficulty)) {
                    option.selected = true
                }
                document.getElementById("difficulty").appendChild(option)
            }
        }
        if (this.props.back === true) {
            this.setState({
                difficulty: 0
            })
            this.props.backToggle()
        }
    }

    sendDifficulty = (e) => {
        this.props.getDifficulty(e.target.value)
    }

    render() {
        return (
            <div>
                Please Select a Difficulty: &nbsp;
                <select id="difficulty" onChange={this.sendDifficulty.bind(this)}>
                </select>
            </div>
        )
    }
}

export default DifficultyOption