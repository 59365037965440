import React, { Component } from 'react'
import { getAllTimeRankings, getMonthRankings, MAX_RANKINGS_ENTRIES } from './RankingsFunctions'
import { MDBDataTable } from 'mdbreact'
import Loader from 'react-loader-spinner'
import './Rankings.css'

const columns = [
    {
        label: '#',
        field: 'rank',
        sort: 'asc',
    },
    {
        label: 'Username',
        field: 'userName',
        sort: 'asc',
    },
    {
        label: 'Province/State',
        field: 'state',
        sort: 'asc',
    },
    {
        label: 'Score',
        field: 'score',
        sort: 'asc',
    },
]

class Rankings extends Component {

    constructor(props) {
        super(props)
        this.state = {
            all_rankings: [],
            month_rankings: [],
            loading: false,
            all_error: false,
            month_error: false,
            all_error_message: '',
            month_error_message: ''
        }
    }

    redirectToUserProfile(userName) {
        this.props.history.push('/profile/' + userName)
    }


    removeSearchLabel() {
        for (var s = 0; s < document.getElementsByTagName('label').length; s++) {
            document.getElementsByTagName('label')[s].setAttribute('id', 'remove_' + s)
        }
        document.getElementById('remove_0').childNodes[0].textContent = ''
        document.getElementById('remove_1').childNodes[0].textContent = ''
    }

    componentWillMount() {
        this.setState({
            loading: true
        })

        getAllTimeRankings().then(response => {
            if (response.data.length > MAX_RANKINGS_ENTRIES) {
                response.data.length = MAX_RANKINGS_ENTRIES
            }
            if (response.data === null || response.data === undefined || response.status !== 200) {
                this.setState({
                    all_error: true,
                    all_error_message: response.data.message
                })
            }
            else {
                let all_rankings = []
                for (var i = 0; i < response.data.length; i++) {
                    let userName = response.data[i].userName
                    all_rankings[i] = {
                        rank: i + 1,
                        userName: response.data[i].userName,
                        state: response.data[i].state,
                        score: response.data[i].score,
                        clickEvent: () => this.redirectToUserProfile(userName)
                    }
                }
                this.setState({
                    all_rankings: all_rankings
                })
            }
        })

        getMonthRankings().then(response => {
            if (response.data.length > MAX_RANKINGS_ENTRIES) {
                response.data.length = MAX_RANKINGS_ENTRIES
            }
            if (response.data === null || response.data === undefined || response.status !== 200) {
                this.setState({
                    month_error: true,
                    month_error_message: response.data.message
                })
            }
            else {
                let month_rankings = []
                for (var i = 0; i < response.data.length; i++) {
                    let userName = response.data[i].userName
                    month_rankings[i] = {
                        rank: i + 1,
                        userName: response.data[i].userName,
                        state: response.data[i].state,
                        score: response.data[i].score,
                        clickEvent: () => this.redirectToUserProfile(userName)
                    }
                }
                this.setState({
                    month_rankings: month_rankings
                })
            }
        })

        this.setState({
            loading: false
        })
    }

    componentDidMount() {
        this.removeSearchLabel()
    }

    render() {
        const loading = (
            <div>
                <br /><br />
                <Loader type='Oval' color='#008fd4' height={80} width={80} />
            </div>
        )

        const renderAfterLoaded = (
            <div className='row'>
                <div className='col'>
                    <h2>This Month</h2>
                    <p className='text-center rankings_descriptions'>This month's learners are rising to the top!</p>
                    <div>
                        <MDBDataTable
                            maxHeight='75vh'
                            striped
                            bordered
                            hover
                            responsive
                            style={{ cursor: 'pointer' }}
                            paging={false}
                            data={{ columns: columns, rows: this.state.month_rankings }}
                        />
                        <span style={{ color: 'red' }}>{this.state.month_error ? this.state.month_error_message : ''}</span>
                    </div>
                </div>
                <div className='col'>
                    <h2>All Time</h2>
                    <p className='text-center rankings_descriptions'>Our All Time high scorers. Wow look at them go!</p>
                    <div>
                        <MDBDataTable
                            maxHeight='75vh'
                            striped
                            bordered
                            hover
                            responsive
                            style={{ cursor: 'pointer' }}
                            paging={false}
                            data={{ columns: columns, rows: this.state.all_rankings }}
                        />
                        <span style={{ color: 'red' }}>{this.state.all_error ? this.state.all_error_message : ''}</span>
                    </div>
                </div>
            </div>
        )

        return (
            <div>
                <h1 className="h1-upward">Rankings</h1>
                <p className="rankings_descriptions">
                Conquer the quizzes quickly and you may make it to this list!<br /><br />
                </p>
                <div className='container'>
                    {this.state.loading ? loading : renderAfterLoaded}
                </div>
            </div>
        )
    }
}

export default Rankings