import React, { Component } from 'react'
import { confirmAccount } from '../UserFunctions'

const successMsg = 'Your account has been confirmed! You will be redirected to the login page shortly.';

class Confirm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            error: false,
            success: false,
            errorMsg: '',
        }
    }

    componentDidMount() {
        if (localStorage.getItem("userName") !== null) {
            localStorage.clear()
            window.location.reload(true)
        } 
        confirmAccount(this.props.match.params.token).then(res => {
            if (res.status === 200) {
                this.setState({success: true})
                setTimeout(() => { this.props.history.push('/login') }, 4000)
                return;
            }
            else {
                this.setState({error: true, errorMsg: res.data.message})
            }
        })
    }

    render() {
        return (
            <div>
                <h1 style={{ marginTop: '4rem' }}>{this.state.success ? successMsg : ''}</h1>
                <h1 style={{ marginTop: '4rem' }}>{this.state.error ? this.state.errorMsg : ''}</h1>            
            </div>
        )
    }
}

export default Confirm